import React, { useEffect, useState } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { logout } from "../../../../utils/Storage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import deliceImg from "../../../../assets/img/deliceHolding.png";
import ProgressDialog from "../../../../components/ProgressDialog";
import { saveAs } from "file-saver";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { useStore } from "../../../../contexts/JWTAuthContext";

import {
  fetchAllActionFormLocals,
  fetchAllVisitCenterFormLocals,
  fetchOtherFormLocals,
} from "../../../../../electron/services/divisionService";
import { fetchAllAuditFormLocals } from "../../../../../electron/services/auditFormService";
import { fetchAllRecapFormLocals } from "../../../../../electron/services/recapFormService";
import NotificationsDropwDown from "../../../../components/NotificationsDropwDown";
import useNotifs from "../../../../hooks/useNotifs";
import { isElectron } from "../../../../helpers/isElectron";
import { fetchUpdatedAuditLocals } from "../../../../../electron/services/auditForm";
import { getGuide } from "redux/slices/export";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "redux/slices/audit_hygien";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const [notifications, setNotifications] = useState(0);
  const [isLogout, setLogout] = useState(false);
  const { count } = useNotifs();
  const electron = isElectron();
  const goto = useHistory().push;
  const { user } = useStore();

  useEffect(() => {
    if (isElectron()) {
      fetchAllActionFormLocals()
        .then((forms) => {
          if (forms) {
            setNotifications((prevCount) => prevCount + forms.length);
          }
        })
        .then(() => {
          fetchOtherFormLocals().then((forms) => {
            if (forms) {
              setNotifications((prevCount) => prevCount + forms.length);
            }
          });
        })
        .then(() => {
          fetchAllVisitCenterFormLocals().then((forms) => {
            if (forms) {
              setNotifications((prevCount) => prevCount + forms.length);
            }
          });
        })
        .then(() => {
          fetchAllAuditFormLocals().then((forms) => {
            if (forms) {
              setNotifications((prevCount) => prevCount + forms.length);
            }
          });
        })
        .then(() => {
          fetchAllRecapFormLocals().then((forms) => {
            if (forms) {
              setNotifications((prevCount) => prevCount + forms.length);
            }
          });
        })
        .then(() => {
          fetchUpdatedAuditLocals().then((forms) => {
            if (forms) {
              setNotifications((prevCount) => prevCount + forms.length);
            }
          });
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        })
        .finally(() => {});
    } else {
      if (user?.userId !== undefined)
        dispatch(getNotifications({ id: user?.userId }));
    }
  }, [user?.userId]);

  const { onlineNotifications } = useSelector((state) => state.auditHygien);
  useEffect(() => {
    localStorage.setItem("notifs", notifications);
  }, [notifications]);

  useEffect(() => {
    let totalNotifs = notifications >= count ? notifications : count;
    setNotifications(totalNotifs);
  }, [count]);

  const logOutAdmin = () => {
    if (isElectron()) {
      setLogout(true);
      setNotifications(true);
    } else {
      logout();
      window.location.reload();
    }
  };
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={deliceImg} width={80} height={60} />
        </RouterLink>
        <Hidden>
          <IconButton
            style={{ marginLeft: "45px" }}
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.flexGrow} />
        {!isElectron() && (
          <Tooltip title="Guide D’utilisation">
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={() =>
                dispatch(getGuide()).then((res) => {
                  saveAs(
                    process.env.REACT_APP_API_IMAGE_URL + res.payload.file,
                    res.payload.file.split("/").slice(-1)[0]
                  );
                })
              }
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        )}
        <NotificationsDropwDown
          totalNotifications={
            isElectron() ? notifications : onlineNotifications
          }
          userId={user?.userId}
          electron={electron}
        />
        {electron && (
          <ProgressDialog
            isLogout={isLogout}
            setLogout={setLogout}
            logout={logout}
            setNotifications={setNotifications}
          />
        )}

        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={logOutAdmin}
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
