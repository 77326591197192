import { decode } from "jsonwebtoken";
import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
import { fetchParamsByIdLocal } from "./analysis_parametersService";
import { fetchDriverByIdLocal } from "./driverService";
import { fetchVisiteCenterById, getAfterDate } from "./visitCenterForm";
import jwt_decode from "jwt-decode";


export const getRecaps = async () => {
  return await api_get(
    `${configUrl}/recaps?page=&pagination=false&createdAt[after]=${getAfterDate()}`
  );
};
export const fetchRecapsLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("recaps")
      .toArray()
      .then((recaps) => {
        resolve(recaps);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const saveRecapFormLocal = (form) => {
  return new Promise((resolve, reject) => {
    db.table("recapForm")
      .add({
        form: form,
        isLocal: 1,
      })
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAllRecapFormLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("recapForm")
      .where("isLocal")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};

export const addRecapItem = (form) => {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("token");
    const decoded = token && jwt_decode(token);
    let analysisParameterValues = [];
    let analysisRecap = [];
    let analysisParameter = [];
    let visitCenter = await fetchVisiteCenterById(form.visitCenter).then(
      (result) => result
    );
   let date = new Date();
   
    for (let i = 0; i <= form.analysisRecap.length - 1; i++) {
      for (
        let j = 0;
        j <= form.analysisRecap[i].analysisParameterValues.length - 1;
        j++
      ) {
        analysisParameter = await fetchParamsByIdLocal(
          form.analysisRecap[i].analysisParameterValues[j].analysisParameter
        ).then((result) => result);
        analysisParameterValues[j] = {
          ...form.analysisRecap[i].analysisParameterValues[j],
          analysisParameter: analysisParameter[0],
        };
      }

      analysisRecap[i] = {
        ...form.analysisRecap[i],
        analysisParameterValues: analysisParameterValues,
      };
    }
    db.table("recaps")
      .add({
        ...form,
        visitCenter: visitCenter[0],
        createdAt:date.toISOString() ,
        createdBy:{name:decoded.username},
        analysisRecap: analysisRecap,
        isLocal: true,
      })
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const MarkedRecap = (data) => {
  return new Promise((resolve, reject) => {
    db.allVisitCentre
      .update(data.id, {recap:1})
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};