import { combineReducers } from "@reduxjs/toolkit";
import { reducer as notifierReducer } from "../slices/notifier";
import { reducer as visitCenterReducer } from "../slices/visitCenter";
import { reducer as preliminary_checksReducer } from "./../slices/priliminary_checks";
import { reducer as analysis_parametersReducer } from "./../slices/analysis_parameters";
import { reducer as driversReducer } from "./../slices/drivers";
import { reducer as refusalsReducer } from "./../slices/refusals";
import { reducer as analyses_visitsReducer } from "./../slices/analyses_visits";
import { reducer as requirementsThemesReducer } from "./../slices/requirements_themes";
import { reducer as otherFormReducer } from "./../slices/otherForm";
import { reducer as zoneReducer } from "./../slices/zone";
import {reducer as actionPlanReducer }from "./../slices/actionPlan";
import {reducer as audit_hygienReducer} from "../slices/audit_hygien";
import {reducer as noteReducer} from "../slices/note"
import {reducer as recap_formReducer} from "./../slices/recap_form"
import {reducer as governorateReducer} from "./../slices/governorate"
import {reducer as authReducer} from "./../slices/auth"
import {reducer as exportReducer} from "./../slices/export"
import {reducer as visitTypesReducer}from "./../slices/visitsTypes"
const combinedReducer = combineReducers({
  notifier: notifierReducer,
  actionPlan:actionPlanReducer,
  governorate:governorateReducer,
  visitCenter: visitCenterReducer,
  preliminary_checks: preliminary_checksReducer,
  recap_form:recap_formReducer,
  drivers: driversReducer,
  visitTypes:visitTypesReducer,
  analyses_visits: analyses_visitsReducer,
  analysis_parameters: analysis_parametersReducer,
  refusals: refusalsReducer,
  requirements_themes: requirementsThemesReducer,
  zone: zoneReducer,
  otherForm: otherFormReducer,
  auditHygien:audit_hygienReducer,
  notes:noteReducer,
  auth:authReducer,
  export:exportReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
