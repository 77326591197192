import React from 'react';
import { HashRouter, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { isElectron } from '../helpers/isElectron';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const goto = useHistory().push;
  if (isAuthenticated) {    
    if(isElectron()){
      goto("/dashboard");}
      else{return <Redirect to={"/"}/>}
  }


  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
