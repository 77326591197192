import React from "react";
import {
  isAgent,
  isAuditor,
  isAuditResponsible,
  isBuyResponsibele,
} from "./aclPages";

export const checkPermission = (route, roles) => {
  if (route === "/") {
    return true;
  }

  if (
    (route === "/dashboard" ||
      route === "/audit-hygiène-ccl" ||
      route === "/audit-hygiène" ||
      route === "/actionplan" ||
      route === "/actionplanlist" ||
      route === "/actionplanshow" ||
      route === "/audit-hygiène-result-details" ||
      route === "/AuditHygieneDetails/:id") &&
    isAuditor(roles)
  ) {
    return true;
  }
  if (
    (route === "/dashboard" ||
      route === "/visitcenter" ||
      route === "/visitcenterForm" ||
      route === "/primaryCircuit" ||
      route === "/primarycircuitform" ||
      route === "/recaplist" ||
      route === "/formulaire/récap" ||
      route === "/otherlist" ||
      route === "/actionplan" ||
      route === "/VisitCenterDetails" ||
      route === "/recapDetails" ||
      route === "/otherDetails" ||
      route === "/actionplanlist" ||
      route === "/actionplanshow" ||
      route === "/otherform") &&
    isAgent(roles)
  ) {
    return true;
  }
  if (
    (route === "/dashboard" ||
      route === "/audit-hygiène-ccl" ||
      route === "/actionplanlist" ||
      route === "/actionplanshow" ||
      route === "/AuditHygieneDetails/:id") &&
    isAuditResponsible(roles)
  ) {
    return true;
  }
  if (
    (route === "/dashboard" ||
      route === "/audit-hygiène-ccl" ||
      route === "/visitcenter" ||
      route === "/primaryCircuit" ||
      route === "/primaryCircuit" ||
      route === "/recaplist" ||
      route === "/otherlist" ||
      route === "/VisitCenterDetails" ||
      route === "/recapDetails" ||
      route === "/otherDetails" ||
      route === "/actionplanlist" ||
      route === "/actionplanshow" ||
      route === "/AuditHygieneDetails/:id") &&
    isBuyResponsibele(roles)
  ) {
    return true;
  }
};
