import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
import { isElectron } from "../../helpers/isElectron";
import {
  addOtherForm,
  fetchOthersLocal,
  saveOtherFormLocal,
} from "../../../electron/services/otherFormService";
import axios from "./../../utils/axios";
const initialState = {
  otherFormData: [],
  others: [],
  rowData: [],
  getOtherStatus: "idle",
  createOtherStatus: "idle",
  status: "idle",
};

export const createOtherFormLocal = createAsyncThunk(
  "otherData/createOtherForm",

  async (values, thunkAPI) => {
    let data;
    try {
      addOtherForm({ ...values.vals, governorate: values.governorate.id });
      saveOtherFormLocal(values.vals);
      thunkAPI.dispatch(GetOther());
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: " formulaire autre créé avec succès Local",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location.href = "#/otherlist";
      setTimeout(() => {
        thunkAPI.dispatch(GetOther());
      }, 500);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const GetOther = createAsyncThunk(
  "otherData/GetOther",
  async (values, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        const data = await fetchOthersLocal().then((result) => result);
        return data;
      } else {
        const response = await axios.get(`/autres?page=&pagination=false`);

        data = await response.data;
        if (response.status === 200 || response.status === 201) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteOther = createAsyncThunk(
  "otherData/deleteOther",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/autres/${id}`);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(GetOther());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: " formulaire autre supprimé avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createOtherForm = createAsyncThunk(
  "otherData/createOtherForm",

  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/autres`, values.otherdata, {
        headers: { "content-type": "multipart/form-data" },
      });

      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: " formulaire autre créé avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        window.location.href = "#/otherlist";
        thunkAPI.dispatch(GetOther());

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "otherData",
  initialState,
  reducers: {
    getOtherRowData: (state, action) => {
      localStorage.setItem("rowData", JSON.stringify(action.payload));
      state.rowData = action.payload;
    },
  },
  extraReducers: {
    [GetOther.pending]: (state, action) => {
      state.getOtherStatus = "loading";
    },
    [GetOther.fulfilled]: (state, action) => {
      state.getOtherStatus = "succeeded";
      state.others = action.payload;
    },
    [GetOther.rejected]: (state, action) => {
      state.getOtherStatus = "error";
    },
    [createOtherForm.pending]: (state, action) => {
      state.createOtherStatus = "loading";
    },
    [createOtherForm.fulfilled]: (state, action) => {
      state.createOtherStatus = "succeeded";
    },
    [createOtherForm.rejected]: (state, action) => {
      state.createOtherStatus = "error";
    },
  },
});

export const reducer = slice.reducer;
export const { getOtherRowData } = slice.actions;
export default slice;
