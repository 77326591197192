// @flow
import React, { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import EcoIcon from "@material-ui/icons/Eco";
import { Opacity } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditHygienCount } from "redux/slices/audit_hygien";
import { isElectron } from "helpers/isElectron";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#0093e2",
    opacity: "0.8",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 1000,
    color: "white",
    fontSize: 18,
  },
  avatar: {
    height: 100,
    width: 100,
  },
  icon: {
    height: 80,
    width: 80,
    color: "gray",
    opacity: "40%",
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: "white",
    fontSize: "30px",
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
}));
type Props = {
  className: string,
};
const Budget = (props: Props): React$Element<any> => {
  const { className, ...rest } = props;
  const { auditHygiene, auditHygieneCount } = useSelector(
    (state) => state.auditHygien
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAuditHygienCount());
  }, [dispatch]);
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Nombre audit hygiène
            </Typography>
          </Grid>
          <Grid item>
            <EcoIcon className={classes.icon} />
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <Typography className={classes.differenceValue} variant="body2">
            {isElectron()
              ? auditHygiene.length
              : auditHygieneCount && auditHygieneCount}
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          ></Typography>
        </div>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
};

export default Budget;
