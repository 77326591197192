import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
import {
  addRecapItem,
  fetchRecapsLocal,
  MarkedRecap,
  saveRecapFormLocal,
} from "../../../electron/services/recapFormService";
import { isElectron } from "../../helpers/isElectron";
const initialState = {
  recapStatus: "idle",
  status:"idle",
  error: null,
  recaps: [],
};

export const getRecap = createAsyncThunk("recap_form/getRecap", async () => {
  let data;
  try {
    if (isElectron()) {
      const data = await fetchRecapsLocal().then((result) => result);
      return data;
    } else {
      const response = await axios.get(`/recaps?page=&pagination=false`);

      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    }
  } catch (err) {
    console.log(err);

    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const DeleteRecap = createAsyncThunk(
  "recap_form/DeleteRecap",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/recaps/${id}`);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(getRecap());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const addRecapForm = createAsyncThunk(
  "recap_form/addRecapForm",
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data;
    try {
      const response = await axios.post(`recaps`, requestValues);
      data = await response.data;
      if (response.status === 201) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "formulaire recap créé avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        ); 
        window.location.href='#/recaplist'
        thunkAPI.dispatch(getRecap())
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Les données ne sont pas valides",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addRecapLocal = createAsyncThunk(
  "recap_form/addRecapLocal",
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data;
    try {
      saveRecapFormLocal(requestValues);
      MarkedRecap({id:requestValues.visitCenter})
     addRecapItem(requestValues)
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "formulaire recap ajouter avec succès",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location.href='#/recaplist'
      
      setTimeout(()=>thunkAPI.dispatch(getRecap()),500)
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Les données ne sont pas valides",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
//

const slice = createSlice({
  name: "recap_form",
  initialState,
  reducers: {},
  extraReducers: {
    [addRecapForm.pending]: (state, action) => {
      state.recapStatus = "loading";
    },
    [addRecapForm.fulfilled]: (state, action) => {
      state.recapStatus = "succeeded";
      state.recaps.push(action.payload);
    },
    [addRecapForm.rejected]: (state, action) => {
      state.recapStatus = "failed";
      state.error = action.payload;
    },
    [getRecap.pending]: (state, action) => {
      state.status = "loading";
    },
    [getRecap.fulfilled]: (state, action) => {
      state.recaps = action.payload;
    },
    [getRecap.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
