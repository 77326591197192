import axios from "axios";
import { api_get } from "../../src/helpers/Api";
import db from "../db/db";
import { getAfterDate } from "./visitCenterForm";
import {configUrl} from "./../configUrl"
import { fetchCenterById } from "./centerService";
export const getActionPlans = async () => {
  return await api_get(
    `${configUrl}/action_plans?page=&pagination=false&createdAt[after]=${getAfterDate()}`
  );
};

export const fetchAction_PlansLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("actionPlans").filter(x=>x.isDeleted===undefined   )
      .toArray()
      .then((actionPlans) => {
        resolve(actionPlans);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveActionPlanFormLocal = (form) => {
  return new Promise((resolve, reject) => {
  
    db.table("actionForm")
      .add({
        form: form,
        isLocal: 1,
      })
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addActionPlanItem = (form) => {
  return new Promise(async(resolve, reject) => {
    form.actionPlans.forEach(async(item) => {
      let center = await fetchCenterById(item.center).then(result=>result)
      db.table("actionPlans")
      .add({
        center: center[0],
        pointToImprove:item.pointToImprove,
        zone:item.zone,
       date:item.date,
        isLocal: 1,
      })
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  });
};

export const addActionPlanItem2 = (form) => {
  
  return new Promise(async(resolve, reject) => {
    let center = await fetchCenterById(form.center).then(result=>result)
       db.table("actionPlans")
      .add({
        pointToImprove: form.pointToImprove,
        timeLimit: form.timeLimit,
        cost: form.cost,
        progress: form.progress,
        payBack: form.payBack,
        correction: form.correction,
        date: form.date,
        center: center[0],
        isLocal: 1,
      })
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    });
};
export const UpdateActionPlan = (data) => {
  return new Promise((resolve, reject) => {
    db.actionPlans
      .update(data.id, data.data)
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const deleteActionPlanElectron = (data) => {
  return new Promise((resolve, reject) => {
    db.actionPlans
      .update(data.id, data.data)
      .then((res) => {
          resolve(true);
        })
        .catch((err) => {
            console.log(err);
            reject(err);
          });
   
  });
};
