import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
export const getCenters = async () => {
  return await api_get(`${configUrl}/centers?page=&pagination=false`);
};

export const fetchCenterbyZoneLocal = (zone) => {
  return new Promise((resolve, reject) => {
    db.table("centers")
      .where("governorate.id")
      .equals(zone)
      .toArray()
      .then((centers) => {
        resolve(centers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCenterbyTypeAndZoneLocal = ({ zone, type }) => {
  return new Promise((resolve, reject) => {
    db.table("centers")
      .where("type")
      .equals(type)
      .toArray()
      .then((centers) => {
        resolve(centers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCenterbyTypeLocal = (type) => {
  return new Promise((resolve, reject) => {
    db.table("centers")
      .where("type")
      .equals(type)
      .toArray()
      .then((centers) => {
        resolve(centers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCenterById = (id) => {
  return new Promise((resolve, reject) => {
    db.table("centers")
      .where("id")
      .equals(id)
      .toArray()
      .then((center) => {
        resolve(center);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
