import axios from 'axios';
import headers from 'helpers/headers';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});
export const token = localStorage.getItem('token');
export const token_type = localStorage.getItem('token_type');
api.interceptors.request.use(
  (response) => response,
  (error) => {
      throw error.response.data.message || 'Something went wrong';
  }
);
export default api;