import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MinimalLayout from "./layouts/Minimal";
import MainLayout from "./layouts/Main";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import { useStore } from "./contexts/JWTAuthContext";
import Dashboard from "./views/Dashboard/index";
import LoginView from "./views/LoginView";
import { Helmet } from "react-helmet";
import { checkPermission } from "utils/acl/aclRouter";
export const RenderRoutes = ({ routes }) => {
  const { user, isAuthenticated } = useStore();
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Layout>
                  <Guard>
                    <Helmet>
                      <title>{route.title}</title>
                    </Helmet>
                    {route.routes ? (
                      <RenderRoutes routes={route.routes} />
                    ) : user && checkPermission(route.path, user.roles) ? (
                      <Component {...props} />
                    ) : !user && !isAuthenticated ? (
                      <LoginView />
                    ) : (
                      <Dashboard />
                    )}
                  </Guard>
                </Layout>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    layout: MinimalLayout,
    path: "/404",
    component: lazy(() => import("./views/NotFound")),
  },
  {
    exact: true,
    layout: MinimalLayout,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./views/LoginView")),
  },

  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/dashboard",
        component: lazy(() => import("./views/Dashboard")),
      },
      {
        exact: true,
        path: "/visitcenterForm",
        component: lazy(() => import("./views/VisitCenterForm/index")),
        title: "visit center Form",
      },
      {
        exact: true,
        path: "/visitcenter",
        component: lazy(() => import("./views/VisiteCentres")),
        title: "visit center Form",
      },
      {
        exact: true,
        path: "/primaryCircuit",
        component: lazy(() => import("./views/CircuitPrimaire")),
        title: "liste de circuit primaire",
      },
      {
        exact: true,
        path: "/primarycircuitform",
        component: lazy(() => import("./views/PrimaryCircuitForm")),
        title: "primary circuit  Form",
      },
      {
        exact: true,
        path: "/VisitCenterDetails",
        component: lazy(() => import("./views/VisitCenterDetails")),
        title: "visit center Details",
      },
      {
        exact: true,
        path: "/otherform",
        component: lazy(() => import("./views/OtherForm")),
        title: "primary circuit  Form",
      },
      {
        exact: true,
        path: "/otherlist",
        component: lazy(() => import("./views/OtherList")),
        title: "autres liste",
      },
      {
        exact: true,
        path: "/audit-hygiène-ccl",
        component: lazy(() => import("./views/HygieneAuditCCL")),
        title: "primary circuit  Form",
      },
      {
        exact: true,
        path: "/audit-hygiène-result-details",
        component: lazy(() =>
          import("./views/HygieneAudit/AuditResultDetails")
        ),
        title: "audit hygiène details de result",
      },
      {
        exact: true,
        path: "/actionplan",
        component: lazy(() => import("./views/ActionPlan")),
        title: "Plan d'action",
      },

      {
        exact: true,
        path: "/formulaire/récap",
        component: lazy(() => import("./views/RecapForm/Form")),
        title: "formulaire récap",
      },

      {
        exact: true,
        path: "/recaplist",
        component: lazy(() => import("./views/RecapList")),
        title: "recap liste",
      },
      {
        exact: true,
        path: "/audit-hygiène",
        component: lazy(() => import("./views/HygieneAudit/Form")),
        title: "formulaire audit-hygiène",
      },
      {
        exact: true,
        path: "/AuditHygieneDetails/:id",
        component: lazy(() => import("./views/HygieneAuditDetail/index")),
        title: "audit-hygiène Détails",
      },
      {
        exact: true,
        path: "/recapDetails",
        component: lazy(() => import("./views/RecapDetails")),
        title: "recap details",
      },
      {
        exact: true,
        path: "/otherDetails",
        component: lazy(() => import("./views/OtherDetails")),
        title: "recap details",
      },
      {
        exact: true,
        path: "/actionplanlist",
        component: lazy(() => import("./views/ActionPlanList")),
        title: "liste plan d'action",
      },
      {
        exact: true,
        path: "/actionplanshow",
        component: lazy(() => import("./views/ActionPlanShow")),
        title: "liste plan d'action",
      },
      {
        exact: true,
        path: "/dashboard",
        component: () => <Redirect to="/dashboard" />,
        title: "Dashboard",
      },
    ],
  },
  {
    path: "*",
    layout: MinimalLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />,
        title: "Not found",
      },
    ],
  },
];

export default routes;
