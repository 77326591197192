import Dexie from "dexie";
import { getActionPlans } from "../services/actionPlanService";
import { getAnalysis_parameters } from "../services/analysis_parametersService";
import { getAuditHygiene } from "../services/auditFormService";
import { getCenters } from "../services/centerService";
import { getChecks } from "../services/checksService";
import { getBreeder, getDrivres } from "../services/driverService";
import { getGovernorates } from "../services/governoratesService";
import { getNotes } from "../services/noteService";
import { getAutres } from "../services/otherFormService";
import { getRecaps } from "../services/recapFormService";
import { getRefusals } from "../services/refusalService";
import { getRequirements_themes } from "../services/requirements_themes";
import { getAllVisitCenters } from "../services/visitCenterForm";
import { getZones } from "../services/zoneService";
import { getAllVisitTypes } from "../services/visitTypesService";

import db from "./db";

function init() {
  db.open()
    .catch((error) => {
      console.error(`Couldn't open database ${error}`);
    })
    .then(() => {
      loadCenters();
      loadZones();
      loadActionPlans();
      loadAuditList();
      loadNotesList();
      loadRequirementThemeList();
      loadanAlysis_parameters();
      loadRefusals();
      loadDrivers();
      loadChecks();
      loadAllVisiteCentre();
      loadAutres();
      loadRecaps();
      loadGovernorates();
      loadVisitsType();
      loadBreeder();
    });
}

export function synchroniseDatabase() {
  if (!db) {
    init();
  }
  resetDatabase();
}
export function resetDatabase() {
  const database = Dexie.getDatabaseNames();
  database.then((names) => {
    names.forEach(function(name) {
      if (name !== "firebaseLocalStorageDb") {
        var db = new Dexie(name);
        db.delete()
          .then(function() {})
          .catch(function(err) {
            console.error("Could not delete DBArray: ", name, err);
          })
          .finally(() => {
            db.close();
            init();
          });
      }
    });
  });
  //
}

export function loadCenters() {
  const result = getCenters();
  result.then((data) => {
    db.centers.bulkAdd(data);
  });
}

export function loadZones() {
  const result = getZones();
  result.then((data) => {
    db.zones.bulkAdd(data);
  });
}
export function loadAuditList() {
  const result = getAuditHygiene();
  result.then((data) => {
    db.auditList.bulkAdd(data);
  });
}

export function loadRequirementThemeList() {
  const result = getRequirements_themes();
  result.then((data) => {
    db.requirements_themes.bulkAdd(data);
  });
}
export function loadNotesList() {
  const result = getNotes();
  result.then((data) => {
    db.notes.bulkAdd(data);
  });
}

export function loadActionPlans() {
  const result = getActionPlans();
  result.then((data) => {
    db.actionPlans.bulkAdd(data);
  });
}

export function loadanAlysis_parameters() {
  const result = getAnalysis_parameters();
  result.then((data) => {
    db.analysis_parameters.bulkAdd(data);
  });
}

export function loadRefusals() {
  const result = getRefusals();
  result.then((data) => {
    db.refusals.bulkAdd(data);
  });
}

export function loadDrivers() {
  const result = getDrivres();
  result.then((data) => {
    db.drivers.bulkAdd(data);
  });
}
export function loadChecks() {
  const result = getChecks();
  result.then((data) => {
    db.checks.bulkAdd(data);
  });
}

export function loadRecaps() {
  const result = getRecaps();
  result.then((data) => {
    db.recaps.bulkAdd(data);
  });
}

export function loadAutres() {
  const result = getAutres();
  result.then((data) => {
    db.others.bulkAdd(data);
  });
}

export function loadGovernorates() {
  const result = getGovernorates();
  result.then((data) => {
    db.governorates.bulkAdd(data);
  });
}

export function loadAllVisiteCentre() {
  const result = getAllVisitCenters();
  result.then((data) => {
    db.allVisitCentre.bulkAdd(data);
  });
}

export function loadVisitsType() {
  const result = getAllVisitTypes();
  result.then((data) => {
    db.visitTypes.bulkAdd(data);
  });
}

export function loadBreeder() {
  const result = getBreeder();
  result.then((data) => {
    db.drivers.bulkAdd(data);
  });
}
