import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
import { fetchCenterById } from "./centerService";
import { fetchNotesByIdLocal } from "./noteService";
import { getAfterDate } from "./visitCenterForm";
import jwt_decode from "jwt-decode";

export const getAuditHygiene = async () => {
  return await api_get(
    `${configUrl}/audit_hygienes/synchronize?page=&pagination=false&createdAt[after]=${getAfterDate()}`
  );
};

export const saveAuditFormLocal = (form) => {
  return new Promise((resolve, reject) => {
    db.table("auditForm")
      .add({
        form: form,
        isLocal: 1,
      })
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAuditHygieneLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("auditList")
      .toArray()
      .then((item) => {
        resolve(item);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const fetchAuditHygieneByIdLocal = (id) => {
  return new Promise((resolve, reject) => {
    db.table("auditList")

      .where("id")
      .equals(id)
      .toArray()
      .then((item) => {
        resolve(item);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUpdatedAuditLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("updateAudit")
      .where("isLocal")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};

export const saveUpdateAuditFormLocal = (data) => {
  return new Promise((resolve, reject) => {
    db.table("updateAudit")
      .add({
        form: data,
        isLocal: 1,
      })
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const UpdateAudit = (data) => {
  return new Promise((resolve, reject) => {
    db.auditList
      .update(parseInt(data.id), {
        comment: data.data.comment,
        validated: data.data.validated,
      })
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const fetchAllAuditFormLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("auditForm")
      .where("isLocal")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};

export const addAuditItem = (form) => {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("token");
    const decoded = token && jwt_decode(token);
    let CheckListAudits = [];
    let note = {};
    let center = await fetchCenterById(form.center).then((result) => result);

    for (let i = 0; i <= form.checkListAudits.length - 1; i++) {
      note = await fetchNotesByIdLocal(form.checkListAudits[i].note).then(
        (result) => result
      );
      CheckListAudits[i] = { ...form.checkListAudits[i], note: note[0] };
    }

    db.table("auditList")
      .add({
        ...form,
        center: center[0],
        checkListAudits: CheckListAudits,
        galleryAuditHygienes: form.files,
        createdBy: { name: decoded.username },
        isLocal: true,
      })
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export function deleteAudit() {
  db.auditForm.clear();
}
