import axios from 'axios';
import headers from "../helpers/headers";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
   headers
    
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        throw error.response.data.message || 'Something went wrong';
    }
);

export default axiosInstance;
