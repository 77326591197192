import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";

export const getRefusals = async () => {
  return await api_get(
    `${configUrl}/refusals?page=&pagination=false`
  );
};

export const fetchRefusalsLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("refusals")
      .toArray()
      .then((refusals) => {
        resolve(refusals);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
