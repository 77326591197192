import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
import { fetchChecksLocal } from "../../../electron/services/checksService";
import { isElectron } from "../../helpers/isElectron";
const initialState = {
  preliminary_checks: [],
  preliminary_checksValue: [],
};

export const fetchPreliminary_Checks = createAsyncThunk(
  "preliminary_checks/fetchPreliminary_Checks",
  async () => {
    let data;
    try {
      if (isElectron()) {
        data = await fetchChecksLocal().then((result) => result);
        return data;
      } else {
        const response = await axios.get(
          `/preliminary_checks?page=&pagination=false`
        );
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "preliminary_checks",
  initialState,
  reducers: {
    getPriliminaryChecks: (state, action) => {
      if (
        state.preliminary_checksValue.find(
          (item) => item.preliminaryCheck === action.payload.preliminaryCheck
        )
      ) {
        state.preliminary_checksValue = state.preliminary_checksValue.map(
          (item) =>
            item.preliminaryCheck === action.payload.preliminaryCheck
              ? action.payload
              : item
        );
      } else {
        state.preliminary_checksValue.push(action.payload);
      }
    },
  },
  extraReducers: {
    [fetchPreliminary_Checks.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchPreliminary_Checks.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.preliminary_checks = action.payload;
    },
    [fetchPreliminary_Checks.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getPriliminaryChecks } = slice.actions;
export default slice;
