import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
import { fetchAnalysis_parametersbyTypeLocal } from "./analysis_parametersService";
import { fetchChecksLocal } from "./checksService";
import jwt_decode from "jwt-decode";
import { fetchCenterbyTypeAndZoneLocal } from "./centerService";
import { fetchRefusalsLocal } from "./refusalService";
import { fetchDriversLocal } from "./driverService";

export const getAfterDate = () => {
  var date = new Date();
  date.setDate(date.getDate() - 3);
  const dateAfter = date.toISOString().split("T")[0];
  return dateAfter;
};

export const getAllVisitCenters = async () => {
  return await api_get(
    `${configUrl}/visit_centers/synchronize?page=&pagination=false&createdAt[after]=${getAfterDate()}`
  );
};

export const fetchVisitCentersLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("allVisitCentre")
      .where("type")
      .equals("CCL")
      .toArray()
      .then((visitCentersData) => {
        resolve(visitCentersData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchVisitCentersByDateLocal = (date) => {
  return new Promise((resolve, reject) => {
    db.table("allVisitCentre")
      .filter((x) => {
        return x.recap === null && x.date.includes(date);
      })
      .toArray()
      .then((visitCentersData) => {
        resolve(visitCentersData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCircuitPrimaireLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("allVisitCentre")
      .where("type")
      .equals("CP")
      .toArray()
      .then((circuitprimaire) => {
        resolve(circuitprimaire);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addVisitCenter = async (form) => {
  var date = new Date();
  const token = localStorage.getItem("token");
  const decoded = token && jwt_decode(token);
  const paramertesValue = await fetchAnalysis_parametersbyTypeLocal(
    form.type
  ).then((result) => result);
  const data = paramertesValue.filter((item) => item.activated === true);
  const checks = await fetchChecksLocal().then((result) => result);
  const drivers = await fetchDriversLocal(form.center).then((result) => result);
  const centers = await fetchCenterbyTypeAndZoneLocal({
    type: form.type === "CCL" ? "Centre de Collecte" : "Ferme",
  }).then((result) => result);

  const filteredCenters = centers.filter(
    (item) => item.governorate && item.governorate.id == form.governorate.id
  );
  const refus = await fetchRefusalsLocal().then((result) => result);
  const visitCenter = [form].map((item) => {
    return {
      ...item,
      recap: null,
      createdAt: date.toISOString(),
      accteptedQuantityTotal: item.analysisVisits
        ? item.analysisVisits.reduce(function(acc, val) {
            return acc + val.acceptedQuantity;
          }, 0)
        : [],
      center: filteredCenters.filter((item) => item.id == form.center)[0],
      user: { name: decoded.username },
      visitCenterChecks: item.visitCenterChecks
        ? item.visitCenterChecks.map((el) => {
            return {
              preliminaryCheck: {
                name: checks.find((check) => check.id == el.preliminaryCheck)
                  .name,
              },
              value: el.value,
            };
          })
        : [],
      analysisVisits: item.analysisVisits
        ? item.analysisVisits.map((analyse) => {
            return {
              ...analyse,
              driver: drivers.find((item) => item.id == analyse.driver),
              analysisRefusals: analyse.analysisRefusals.map((ref) => {
                return {
                  refusal: {
                    name: refus.find((elt) => elt.id == ref.refusal).name,
                  },
                  quantity: ref.quantity,
                };
              }),
              analysisParameterValues: analyse.analysisParameterValues.map(
                (el) => {
                  return {
                    analysisParameter: {
                      name: data.find(
                        (parameter) => parameter.id == el.analysisParameter
                      ).name,
                    },
                    value: el.value,
                  };
                }
              ),
            };
          })
        : [],
    };
  });
  return new Promise((resolve, reject) => {
    db.table("allVisitCentre")
      .add(visitCenter[0])
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const fetchVisiteCenterById = (id) => {
  return new Promise((resolve, reject) => {
    db.table("allVisitCentre")
      .where("id")
      .equals(id)
      .toArray()
      .then((visiteCenter) => {
        resolve(visiteCenter);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const saveVisitCenterFormLocal = (form) => {
  return new Promise((resolve, reject) => {
    db.table("visitCenterForm")
      .add({
        form: form,
        isLocal: 1,
      })
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
