import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { fetchZonesLocal } from "../../../electron/services/zoneService";
import { isElectron } from "../../helpers/isElectron";
const initialState = {
  zones: [],
  fetchstatus: 'idle',
};
export const fetchZone = createAsyncThunk(
  "zones/fetchZone",
  async () => {
    let data;
    try {
      if (isElectron()) {
        data =  await fetchZonesLocal().then((result)=> result  );
        return data;
      } else {
        const response = await axios.get(`/zones?page=&pagination=false`);
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "zones",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchZone.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchZone.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.zones = action.payload;
    },
    [fetchZone.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getItem } = slice.actions;

export default slice;
