export const aclPages = (pages, roles, groups) => {
  if (isSupAdmin(roles)) {
    return pages;
  }
  let result = [];

  pages.forEach(function(page) {
    let temp = false;

    if (page) {
      temp = checkPermissionPage(formatTilePage(page.title), roles);

      if (temp) {
        result.push(page);
       }
    }
  });
  return result;
};

const checkPermissionPage = (roleName, roles) => {

  let exist = false;
  if (roleName === "*") {
    return true;
  }
  if (
    (roleName.includes("PLAND'ACTION_VIEW") ||
      roleName.includes("ACCUEIL_VIEW") ||
      roleName.includes("AUDITHYGIÈNECCL_VIEW") ||
      roleName.includes("AUDITHYGIÈNE_VIEW")) &&
    isAuditor(roles)
  ) {
    return true;
  }
  if (
    (roleName.includes("LISTEPLAND'ACTION_VIEW") ||
      roleName.includes("ACCUEIL_VIEW") ||
      roleName.includes("AUDITHYGIÈNECCL_VIEW") ||
      roleName.includes("LISTEVISITECENTRE_VIEW") ||
      roleName.includes("LISTEDEFORMULAIREAUTRE_VIEW") ||
      roleName.includes("LISTEDESRECAPS_VIEW") ||
      roleName.includes("LISTECIRCUITPRIMAIRE_VIEW" ) ||
      roleName.includes("LISTEDESRECAPS_VIEW")) &&
    isBuyResponsibele(roles)
  ) {
    return true;
  }
  if (
    (roleName.includes("LISTEPLAND'ACTION_VIEW") ||
      roleName.includes("ACCUEIL_VIEW") ||
      roleName.includes("AUDITHYGIÈNECCL_VIEW")) &&
    isAuditResponsible(roles)
  ) {
    return true;
  }
  if (
    (roleName.includes("FORMULAIRECIRCUITPRIMAIRE_VIEW") ||
      roleName.includes("FORMULAIRECIRCUITPRIMAIRE_VIEW") ||
      roleName.includes("FORMULAIRERÉCAP_VIEW") |
        roleName.includes("FORMULAIREAUTRE_VIEW") ||
      roleName.includes("ACCUEIL_VIEW") ||
      roleName.includes("PLAND'ACTION_VIEW") ||
      roleName.includes("FORMULAIREVISITECENTRE_VIEW") ||
      roleName.includes("LISTEDESRECAPS_VIEW") ||
      roleName.includes("CIRCUITPRIMAIRE_VIEW") ||
      roleName.includes("LISTEDEFORMULAIREAUTRE_VIEW") || 
      roleName.includes("LISTEVISITECENTRE_VIEW")) &&
    isAgent(roles)
  ) {
    return true;
  }

};

export const formatAdminRoleName = (roleName, withAction = false) => {
  roleName = roleName.replace("ROLE_", "");
  if (withAction) {
    return roleName;
  }
  return roleName.slice(0, roleName.indexOf("_"));
};

const formatTilePage = (title) => {
  return (
    title
      ?.replace(/\s/g, "")
      ?.replace("-", "")
      ?.toUpperCase() + "_VIEW"
  );
};

export const isSupAdmin = (roles) => {
  return roles?.includes("ROLE_ADMIN");
};
export const isAuditor = (roles) => {
  return roles?.includes("AUDITEUR");
};
export const isAgent = (roles) => {
  return roles?.includes("AGENT_VISITE_CENTRE");
};

export const isAuditResponsible = (roles) => {
  return roles?.includes("RESPONSABLE_AUDIT");
};
export const isBuyResponsibele = (roles) => {
  return roles?.includes("RESPONSABLE_ACHAT_LAIT");
};
export const checkAdminName = (groups, name) => {
  let isAdmin = false;
  groups.map((group) => {
    if (group?.name === name) {
      isAdmin = true;
    }
  });
  return isAdmin;
};
