import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { isElectron } from "../../helpers/isElectron";
import {
  fetchBreederLocal,
  fetchDriversLocal,
} from "../../../electron/services/driverService";
const initialState = {
  drivers: [],
  breeder: [],
};

export const fetchdrivers = createAsyncThunk(
  "drivers/fetchdrivers",
  async (query, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        data = await fetchDriversLocal(query.center).then((result) => result);

        const filteredData = data?.filter(
          (item) => parseInt(item.center.id) === query.center
        );
        return filteredData;
      } else {
        const response = await axios.get(
          `/drivers?page=&pagination=false&center=${query.center}`
        );
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchBreeders = createAsyncThunk(
  "drivers/fetchBreeders",
  async (query, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        data = await fetchBreederLocal(query.center).then((result) => result);

        const filteredData = data?.filter(
          (item) => parseInt(item.center.id) === query.center
        );
        return filteredData;
      } else {
        const response = await axios.get(
          `/breeders?page=&pagination=false&center=${query.center}`
        );
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "drivers",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchdrivers.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchdrivers.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.drivers = action.payload;
    },
    [fetchdrivers.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
    [fetchBreeders.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchBreeders.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.breeder = action.payload;
    },
    [fetchBreeders.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;

export default slice;
