import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "./notifier";
import { isElectron } from "../../helpers/isElectron";
import axios from "./../../utils/axios";

const initialState = {
  actionPlanData: [],
  Audit_HygienesExportData: [],
  visit_centersExportData: [],
  autresExportData: [],
  recapsExportData: [],
  status: "idle",
  createStatus: "idle",
  deleteStatus: "idle",
};

export const getAudit_HygienesData = createAsyncThunk(
  "export/getAudit_HygienesData",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/audit_hygienes/export/${body.id}/${body.format}`,
        {
          headers: {
            "Content-Type": `application/${body.format}`,
          },
        }
      );
      data = await response?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
      // }
    } catch (err) {
      console.log(err)
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            //  TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getvisit_centersData = createAsyncThunk(
  "export/getvisit_centersData",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/visit_centers/export/${body.id}/${body.format}`,
        {
          headers: {
            "Content-Type": `application/${body.format}`,
          },
        }
      );
      data = await response?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
      // }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            //  TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getautresData = createAsyncThunk(
  "export/getautresData",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/autres/export/${body.id}/${body.format}`,
        {
          headers: {
            "Content-Type": `application/${body.format}`,
          },
        }
      );
      data = await response?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
      // }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            //  TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getrecapsData = createAsyncThunk(
  "export/getrecapsData",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/recaps/export/${body.id}/${body.format}`,
        {
          headers: {
            "Content-Type": `application/${body.format}`,
          },
        }
      );
      data = await response?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
      // }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            //  TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getActionPlanData = createAsyncThunk(
  "export/getActionPlanData",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/action_plans/export/${body.id}/${body.format}`,
        {
          headers: {
            "Content-Type": `application/${body.format}`,
          },
        }
      );
      data = await response?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
      // }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            //  TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getGuide = createAsyncThunk(
  "export/getGuide",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/user-guide`,
        
      );
      data = await response?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
      // }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            //  TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "export",
  initialState,
  reducers: {},
  extraReducers: {
    [getAudit_HygienesData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAudit_HygienesData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.Audit_HygienesExportData = action.payload;
    },
    [getAudit_HygienesData.rejected]: (state, action) => {
      state.status = "error";
    },
    [getvisit_centersData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getvisit_centersData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.visit_centersExportData = action.payload;
    },
    [getvisit_centersData.rejected]: (state, action) => {
      state.status = "error";
    },
    [getautresData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getautresData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.autresExportData = action.payload;
    },
    [getautresData.rejected]: (state, action) => {
      state.status = "error";
    },
    [getrecapsData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getrecapsData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.recapsExportData = action.payload;
    },
    [getrecapsData.rejected]: (state, action) => {
      state.status = "error";
    },
    [getActionPlanData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getActionPlanData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      //state.recapsExportData = action.payload;
    },
    [getActionPlanData.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const reducer = slice.reducer;
export const { getActionPlanRowData } = slice.actions;
export default slice;
