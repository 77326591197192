import {api_get} from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
export const getZones = async () => {
    return await api_get(`${configUrl}/zones?page=&pagination=false`);
};

export const fetchZonesLocal = () =>  {
    return new Promise((resolve, reject) => {
        db.table("zones")
            .toArray()
            .then((zones) => {
                resolve(zones)
            })
            .catch(err => {
                reject(err)
            })
    })
}
