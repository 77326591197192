import {api_get} from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";

export const getRequirements_themes = async () => {
    return await api_get(`${configUrl}/requirements_themes?page=&pagination=false`);
};

export const fetchRequirements_themesLocal = () =>  {

    return new Promise((resolve, reject) => {
    
        db.table("requirements_themes")
            .toArray()
            .then((action_plans) => {
                resolve(action_plans)
            })
            .catch(err => {
                reject(err)
            })

    })
}