import {api_get} from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";

export const getNotes = async () => {
    return await api_get(`${configUrl}/notes?page=&pagination=false`);
};

export const fetchNotesLocal = () =>  {

    return new Promise((resolve, reject) => {
    
        db.table("notes")
            .toArray()
            .then((action_plans) => {
                resolve(action_plans)
            })
            .catch(err => {
                reject(err)
            })

    })
}

export const fetchNotesByIdLocal = (id) => {
    return new Promise((resolve, reject) => {
      db.table("notes")
        .where("id")
        .equals(id)
        .toArray()
        .then((note) => {
          resolve(note);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  