import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContentText,
  IconButton,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SnackBar from "./SnackBar";
import { makeStyles } from "@material-ui/styles";
import Loading from "./Loading/Loading";
import { SyncAltRounded } from "@material-ui/icons";

import { getActionPlan } from "../redux/slices/actionPlan";
import { GetOther } from "../redux/slices/otherForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchVisitCenters } from "../redux/slices/visitCenter";

import { fetchAuditHygien } from "../redux/slices/audit_hygien";
import { getRecap } from "../redux/slices/recap_form";
import SynchroniseDB from "./Synchronise";
import { connexionCheck } from "redux/slices/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  style: {
    color: "red",
  },
}));

const ProgressDialog = (props) => {
  const { setNotifications, isLogout, logout, setLogout } = props;
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessageModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSynchronised, setSynchronised] = useState(false);
  const { connected } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlePreconfirmModalOpen = () => {
    dispatch(connexionCheck());
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpen(false);

    setTimeout(() => {
      dispatch(getRecap());
      dispatch(getActionPlan());
      dispatch(fetchAuditHygien());
      dispatch(GetOther());
      if (window.location.href.includes("#/visitcenter")) {
        dispatch(fetchVisitCenters({ type: "CCL" }));
      }
      if (window.location.href.includes("#/primaryCircuit")) {
        dispatch(fetchVisitCenters({ type: "CP" }));
      }
    }, 800);

    if (isLogout) {
      logout();
      setTimeout(() => {
        window.location.reload()
    }, 800);
    }
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleMessageClose = () => {
    setOpenMessageModal(false);
  };
  const handleModalClose = () => {
    setOpenModal(false);
    setLogout(false);
  };

  const handleSubmit = () => {
    setSynchronised(false);
    handleClickOpen();
    setOpenModal(false);

    SynchroniseDB(dispatch, setSynchronised, setNotifications, connected);
    localStorage.setItem("notifs", 0);
    setNotifications(0);
    //setLogout(false)
  };

  return (
    <div>
      <IconButton
        className={classes.signOutButton}
        color="inherit"
        onClick={handlePreconfirmModalOpen}
      >
        <SyncAltRounded />
      </IconButton>
      <SnackBar
        open={openMessage}
        message={message}
        severity={severity}
        handleClose={handleMessageClose}
      />

      <Dialog
        open={isLogout ? isLogout : openModal}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pre-Confirmation</DialogTitle>
        <DialogContent>
          {connected ? (
            <DialogContentText id="alert-dialog-description">
              Toutes les données seront envoyées vers la base des données
              distante{" "}
              <span className={classes.style}>
                {" "}
                Voulez vous continuer l'opération?{" "}
              </span>{" "}
              ?
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              S'il vous plaît vérifier votre connexion internet, puis appuyer
              sur <span className={classes.style}>" Réessayer " </span> pour
              synchroniser ?
            </DialogContentText>
          )}
          {/* ajouter un dialogue pour qdqdqd */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            {connected ? "Non" : "Annuler"}
          </Button>
          <Button
            onClick={() => {
              dispatch(connexionCheck());
              connected && handleSubmit();
            }}
            color="primary"
            autoFocus
          >
            {connected ? "Oui" : "Réessayer"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Synchronisation des données
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isSynchronised && !isLogout
              ? "Les informations ont été mises à jour avec succès! Merci pour votre patience "
              : isLogout
              ? 'Les informations ont été mises à jour avec succès! appuyer sur "Fermer" pour déconnecté'
              : !connected && !isSynchronised && !isLogout
              ? "S'il vous plaît vérifier votre connexion internet, appyez sur << Réessayer >>"
              : "Veuillez patienter, la mis à jour des informations peut prendre du temps"}
          </DialogContentText>
          {!isSynchronised && <Loading />}
        </DialogContent>
        <DialogActions>
          {isSynchronised && (
            <Button onClick={handleClose} color="primary" autoFocus>
              Fermer
            </Button>
          )}
          {!connected && !isSynchronised && !isLogout && (
            <Button onClick={handleClose} color="primary" autoFocus>
              Réessayer
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SnackBar
        open={openAlert}
        message={message}
        severity={severity}
        handleClose={handleCloseAlert}
      />
    </div>
  );
};
export default ProgressDialog;
