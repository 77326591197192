import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import JWTLogin from "./JWTLogin";
import deliceImg from "../../assets/img/deliceHolding.png";
import { isElectron } from "helpers/isElectron";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  bannerChip: {
    marginRight: theme.spacing(2),
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 500,
  },
  boldText: {
    fontWeight: "bolder",
    margin: "15px",
  },
  welcomeText: {
    fontWeight: 300,
  },

  currentMethodIcon: {
    height: 40,
    "& > img": {
      width: "auto",
      maxHeight: "100%",
    },
  },
}));

const LoginView = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} title="Login">
      <Container className={classes.cardContainer} maxWidth="sm">
        <Card>
          <CardContent className={classes.cardContent}>
            <Box mb={10}>
              <div className={classes.cardHeader}>
                <img src={deliceImg} height={200} width={300} />
                <Typography
                  className={classes.welcomeText}
                  variant="h1"
                  align="center"
                >
                  Bienvenu(e) !
                </Typography>
                <Typography
                  className={classes.boldText}
                  variant="h3"
                  align="center"
                >
                  Dans notre plateforme Amont Laitier
                </Typography>

                {isElectron() && (
                  <Typography variant="h4" align="center" color="textSecondary">
                    Veuillez-vous connecter avant de se déplacer vers un
                    emplacement hors couverture réseau
                  </Typography>
                )}
              </div>
            </Box>
            <Divider />
            <Typography
              style={{ marginTop: "10px" }}
              variant="body"
              align="center"
              color="textSecondary"
            >
              Merci de mettre votre <strong>Matricule </strong> et votre{" "}
              <strong>Mot de passe</strong> …
            </Typography>
            <Box flexGrow={2} mt={4}>
              <JWTLogin />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default LoginView;
