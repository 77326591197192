import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { fetchRequirements_themesLocal } from "../../../electron/services/requirements_themes";
import { isElectron } from "../../helpers/isElectron";

const initialState = {
  requirementsThemes: [],
  status: "idle",
  error: null,
};

export const fetchRequirementsThemes = createAsyncThunk(
  "requirements_themes/fetchRequirementsThemes",
  async (page) => {
    let data;
    try {
      if (isElectron()) {
        data = await fetchRequirements_themesLocal().then((result) => result);
        return data;
      } else {
        const response = await axios.get(
          `/requirements_themes?page=&pagination=false`
        );
        data = await response.data;
        if (response.status === 200) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "requirements_themes",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRequirementsThemes.pending]: (state) => {
      state.status = "loading";
    },
    [fetchRequirementsThemes.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.requirementsThemes = action.payload;
      localStorage.setItem(
        "requirementsThemes",
        JSON.stringify(state.requirementsThemes)
      );
    },
    [fetchRequirementsThemes.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;

export default slice;
