import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
import { getAfterDate } from "./visitCenterForm";
var date = new Date().toISOString().split("T")[0];
export const getAuditHygiene = async () => {
  return await api_get(
    `${configUrl}/audit_hygienes/synchronize?page=&pagination=false&createdAt[after]=${getAfterDate()}`
  );
};

export const saveAuditFormLocal = (form) => {
  return new Promise((resolve, reject) => {
    db.table("auditForm")
      .add({
        form: form,
        isLocal: 1,
      })
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAuditHygieneLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("auditList")
      .toArray()
      .then((zones) => {
        resolve(zones);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAllAuditFormLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("auditForm")
      .where("isLocal")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};
