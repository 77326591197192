import db from "../db/db";


export const fetchAllLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("otherForm")
      .where("isLocal")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};

export const fetchOtherFormLocals = () =>  {
    return new Promise(async (resolve, reject) => {
        const locals = await db.table("otherForm")
            .where("isLocal")
            .equals(1)
            .toArray();
        if (!locals) {
            reject(locals)
        }
        resolve(locals)
    })
}



export const fetchAllActionFormLocals = () =>  {
    return new Promise(async (resolve, reject) => {
        const locals = await db.table("actionForm")
            .where("isLocal")
            .equals(1)
            .toArray();
        if (!locals) {
            reject(locals)
        }
        resolve(locals)
    })
}

export const fetchUpdatedActionPlanLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("actionPlans")
      .where("isUpdated")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};

export const fetchdeletedActionPlanLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("actionPlans")
      .where("isDeleted")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};

export const fetchAllVisitCenterFormLocals = () => {
  return new Promise(async (resolve, reject) => {
    const locals = await db
      .table("visitCenterForm")
      .where("isLocal")
      .equals(1)
      .toArray();
    if (!locals) {
      reject(locals);
    }
    resolve(locals);
  });
};
