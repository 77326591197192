import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
import { fetchRefusalsLocal } from "../../../electron/services/refusalService";
import { isElectron } from "../../helpers/isElectron";
const initialState = {
  refusals: [],
  refusalsData: [],
  showRefs: [],
  tempArray: [],
};

export const fetchRefusals = createAsyncThunk(
  "refusals/fetchrefusals",
  async (page, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        data = await fetchRefusalsLocal().then((result) => result);
        return data;
      } else {
        const response = await axios.get(`/refusals?page=&pagination=false`);
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createRefusals = createAsyncThunk(
  "refusals/createRefusals",
  async (refusal, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/refusals`, refusal);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "refus créé avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "centers",
  initialState,
  reducers: {
    getRefusals: (state, action) => {
      if (
        state.refusalsData.find(
          (item) => (item.refusal === action.payload.refusal)&&(item.quantity === action.payload.quantity)
        )
      ) {
        state.refusalsData = state.refusalsData.map(
          (item) =>
          (item.refusal === action.payload.refusal)&&(item.quantity === action.payload.quantity)
              ? action.payload
              : item
        );
      } else {
        state.refusalsData.push(action.payload);
        state.tempArray.push(action.payload);
        state.tempArray.forEach((o, i) => (o.id = i + 1));
      }
      
    },
    deleteRefusal: (state, action) => {
      state.refusalsData = state.refusalsData.filter(
        (item) => item.id !== action.payload
      );
    },
    emptyRefs: (state, action) => {
      state.refusalsData = [];
    },
  },
  extraReducers: {
    [fetchRefusals.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchRefusals.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.refusals = action.payload;
    },
    [fetchRefusals.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getRefusals, emptyRefs, deleteRefusal } = slice.actions;

export default slice;
