import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
import { getAfterDate } from "./visitCenterForm";
import jwt_decode from "jwt-decode";
import { fetchCenterbyZoneLocal } from "./centerService";
export const getAutres = async () => {
  return await api_get(
    `${configUrl}/autres?page=&pagination=false&createdAt[after]=${getAfterDate()}`
  );
};

export const fetchOthersLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("others")
      .toArray()
      .then((others) => {
        resolve(others);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addOtherForm = async (form) => {
  const token = localStorage.getItem("token");
  const decoded = token && jwt_decode(token);
  const centers = await fetchCenterbyZoneLocal(form.governorate).then(
    (result) => result
  );

  return new Promise((resolve, reject) => {
    db.table("others")
      .add({
        date: form.date,
        type: form.type,
        remark: form.remark,
        formType: form.formType,
        center: centers.find((item) => item.id === form.center),
        createdBy: { name: decoded.username },
        gallery: form.files,
        isLocal: 1,
      })
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const saveOtherFormLocal = (form) => {
  return new Promise((resolve, reject) => {
    db.table("otherForm")
      .add({
        form: form,
        isLocal: 1,
      })
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
