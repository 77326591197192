import { api_get } from "../../src/helpers/Api";
import db from "../db/db";
import {configUrl} from "./../configUrl"
export const getAnalysis_parameters = async () => {
  return await api_get(
    `${configUrl}/analysis_parameters?page=&pagination=false`
  );
};

export const fetchAnalysis_parametersLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("analysis_parameters")
      .toArray()
      .then((analysis_parameters) => {
        resolve(analysis_parameters);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAnalysis_parametersbyTypeLocal = (type) => {
  return new Promise((resolve, reject) => {
    db.table("analysis_parameters")
      .where("visite")
      .equals(type)
      .toArray()
      .then((analysis_parameters) => {
        resolve(analysis_parameters);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchParamsByIdLocal = (id) => {
  return new Promise((resolve, reject) => {
    db.table("analysis_parameters")
      .where("id")
      .equals(id)
      .toArray()
      .then((analysis_parameters) => {
        resolve(analysis_parameters);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

