// @flow
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import { useSelector } from "react-redux";
import { isElectron } from "helpers/isElectron";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#FD9D59",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 1000,
    color: "white",
    fontSize: 18,
  },
  avatar: {
    height: 100,
    width: 100,
  },
  icon: {
    height: 80,
    width: 80,
    color: "gray",
    opacity: "40%",
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: "white",
    fontSize: "30px",
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
}));

const TotalUsers = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { visitCenters, countVisitCenter } = useSelector(
    (state) => state.visitCenter
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Nombre de visites centre{" "}
            </Typography>
          </Grid>
          <Grid item>
            <HomeWorkIcon className={classes.icon} />
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <Typography className={classes.differenceValue} variant="body2">
            {isElectron() ? visitCenters.length : countVisitCenter}
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          ></Typography>
        </div>
      </CardContent>
    </Card>
  );
};

TotalUsers.propTypes = {
  className: PropTypes.string,
};

export default TotalUsers;
