import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";

export const getDrivres = async () => {
  return await api_get(`${configUrl}/drivers?page=&pagination=false`);
};

export const getBreeder = async () => {
  return await api_get(`${configUrl}/breeders?page=&pagination=false`);
};
export const fetchDriversLocal = (center) => {
  return new Promise((resolve, reject) => {
    db.table("drivers")
      .toArray()
      .then((drivers) => {
        resolve(drivers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchBreederLocal = (center) => {
  return new Promise((resolve, reject) => {
    db.table("breeder")
      .toArray()
      .then((drivers) => {
        resolve(drivers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDriverByIdLocal = (id) => {
  return new Promise((resolve, reject) => {
    db.table("drivers")
      .where("id")
      .equals(id)
      .toArray()
      .then((driver) => {
        resolve(driver);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
