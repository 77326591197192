import React, { Component } from "react";
import { Router, HashRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";
import { AuthProvider } from "./contexts/JWTAuthContext";
import {NotifIncrementProvider} from "./contexts/UpdateNotificationsContext";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import routes, { RenderRoutes } from "./Routes";
import { SnackbarProvider } from "notistack";
import { Notifier } from "layouts";
import "./App.css"
const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators,
};

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={12}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Notifier />
          <HashRouter>
            <AuthProvider>
                <NotifIncrementProvider>
                    <RenderRoutes routes={routes} />
                </NotifIncrementProvider>
            </AuthProvider>
          </HashRouter>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}
