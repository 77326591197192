import {api_get} from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
export const getGovernorates = async () => {
    return await api_get(
      `${configUrl}/governorates?page=&pagination=false`
    );
  };
export const fetchGovernoratesLocal = () =>  {
    return new Promise((resolve, reject) => {
        db.table("governorates")
            .toArray()
            .then((governorates) => {
                resolve(governorates)
            })
            .catch(err => {
                reject(err)
            })
    })
}
