import { Slide } from "@material-ui/core";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isElectron } from "../../helpers/isElectron";
import { enqueueSnackbar } from "./notifier";
import { saveAuditFormLocal } from "../../../electron/services/auditFormService";
import { fetchAuditHygieneLocal } from "../../../electron/services/auditFormService";
import axios from "./../../utils/axios";
import api from "services/api";
import { addActionPlanItem } from "../../../electron/services/actionPlanService";
import {
  addAuditItem,
  fetchAuditHygieneByIdLocal,
  saveUpdateAuditFormLocal,
  UpdateAudit,
  UpdateAuditValidation,
} from "../../../electron/services/auditForm";

import { getActionPlan } from "./actionPlan";

const initialState = {
  auditHygiene: [],
  auditHygieneById: {},
  pointToImprove: [],
  strongPoints: [],
  auditResults: [],
  indiceHygieneFournisseur: "",
  noteTotal: "",
  addStatus: "idle",
  auditHygieneCount: "",
  onlineNotifications: [],
};

export const fetchAuditHygien = createAsyncThunk(
  "audit_hygien/fetchAuditHygien",
  async (query, thunkAPI) => {
    let data;

    try {
      if (isElectron()) {
        data = await fetchAuditHygieneLocal().then((result) => result);
        return data;
      } else {
        const response = await axios.get(
          `audit_hygienes?search=${query.inputText}&page=${Number(query.page) +
            1}&itemsPerPage=${query.rowsPerPage}&pagination=true`
        );
        data = await response.data;
        if (response.status === 200 || response.status === 201) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchAuditHygienCount = createAsyncThunk(
  "audit_hygien/fetchAuditHygienCount",
  async (query, thunkAPI) => {
    let data;

    try {
      if (isElectron()) {
        data = await fetchAuditHygieneLocal().then((result) => result);

        return data;
      } else {
        const response = await axios.get(`audit_hygienes/count`);
        data = await response.data;
        if (response.status === 200 || response.status === 201) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "audit_hygien/getNotifications",
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `notifications?page=1&destinataire=${query.id}&estLue=false`
      );
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const notificationsSeen = createAsyncThunk(
  "audit_hygien/notificationsSeen",
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`notifications/seen`, {});
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(getNotifications({ id: query?.id }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchAuditHygieneById = createAsyncThunk(
  "audit_hygien/fetchAuditHygienById",
  async (query, thunkAPI) => {
    let data;
    const { id } = query;
    try {
      if (isElectron()) {
        data = await fetchAuditHygieneByIdLocal(id).then((result) => result);
        return data[0];
      } else {
        const response = await axios.get(`audit_hygienes/${id}`);
        data = await response.data;
        if (response.status === 200 || response.status === 201) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addAuditHygien = createAsyncThunk(
  "audit_hygien/addAuditHygien",
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data;
    try {
      const response = await axios.post(`audit_hygienes`, requestValues, {
        headers: { "content-type": "multipart/form-data" },
      });
      data = await response.data;

      if (response.status === 201) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "formulaire audit hygiène ajouter avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        window.location.href = "#/audit-hygiène-ccl";
        thunkAPI.dispatch(fetchAuditHygien({ page: 1, rowsPerPage: 5 }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Les données ne sont pas valides",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateAuditValidationLocal = createAsyncThunk(
  "audit_hygien/updateAuditValidationLocal",
  async (body, thunkAPI) => {
    let data;
    try {
      saveUpdateAuditFormLocal({ id: body.id, data: body.data });

      UpdateAudit({ id: body.id, data: body.data });
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: " mise à jour effectuer avec succès",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location.href = "#/audit-hygiène-ccl";

      setTimeout(() => {
        thunkAPI.dispatch(fetchAuditHygien());
        thunkAPI.dispatch(getActionPlan());
      }, 500);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addAuditHygienLocal = createAsyncThunk(
  "audit_hygien/addAuditHygienLocal",
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data;
    try {
      addAuditItem(requestValues);
      addActionPlanItem(requestValues);
      saveAuditFormLocal(requestValues);
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "formulaire ajouter avec succès",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location.href = "#/audit-hygiène-ccl";

      setTimeout(() => {
        thunkAPI.dispatch(fetchAuditHygien());
        thunkAPI.dispatch(getActionPlan());
      }, 500);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Les données ne sont pas valides",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateAuditComment = createAsyncThunk(
  "audit_hygien/updateAuditComment",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `/audit_hygienes/${body.id}/validate`,
        body.data
      );
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(getActionPlan());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              "mise à jour du formulaire audit hygiéne effectuer avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        window.location.href = "#/audit-hygiène-ccl";
        thunkAPI.dispatch(fetchAuditHygien());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
//

const slice = createSlice({
  name: "audit_hygien",
  initialState,
  reducers: {
    getAuditResult: (state, action) => {
      state.pointToImprove = action.payload.pointToImprove;
      state.strongPoints = action.payload.strongPoints;
      state.noteTotal = action.payload.noteTotal;
      state.indiceHygieneFournisseur = action.payload.indiceHygieneFournisseur;
      localStorage.setItem(
        "pointToImprove",
        JSON.stringify(state.pointToImprove)
      );
      localStorage.setItem("strongPoints", JSON.stringify(state.strongPoints));
      localStorage.setItem(
        "indiceHygieneFournisseur",
        JSON.stringify(state.indiceHygieneFournisseur)
      );
      localStorage.setItem("noteTotal", JSON.stringify(state.noteTotal));
    },
  },
  extraReducers: {
    [fetchAuditHygien.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAuditHygien.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.auditHygiene = action.payload;
    },
    [fetchAuditHygien.rejected]: (state, action) => {},
    [updateAuditComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateAuditComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [updateAuditComment.rejected]: (state, action) => {
      state.status = "rejected";
    },
    [notificationsSeen.pending]: (state, action) => {
      state.status = "loading";
    },
    [notificationsSeen.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [notificationsSeen.rejected]: (state, action) => {},

    [getNotifications.pending]: (state, action) => {
      state.status = "loading";
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.onlineNotifications = action.payload;
    },
    [getNotifications.rejected]: (state, action) => {},
    [fetchAuditHygienCount.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAuditHygienCount.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.auditHygieneCount = action.payload;
    },
    [fetchAuditHygienCount.rejected]: (state, action) => {},
    [fetchAuditHygieneById.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAuditHygieneById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (Object.entries(action.payload).length > 0) {
        state.auditHygieneById = action.payload;
      }
    },
    [fetchAuditHygieneById.rejected]: (state, action) => {},

    [addAuditHygien.pending]: (state, action) => {
      state.addStatus = "loading";
    },
    [addAuditHygien.fulfilled]: (state, action) => {
      state.addStatus = "succeeded";
    },
    [addAuditHygien.rejected]: (state, action) => {
      state.addStatus = "rejected";
    },
    [addAuditHygienLocal.pending]: (state, action) => {
      state.addStatus = "loading";
    },
    [addAuditHygienLocal.fulfilled]: (state, action) => {
      state.addStatus = "succeeded";
    },
    [addAuditHygienLocal.rejected]: (state, action) => {
      state.addStatus = "rejected";
    },
  },
});

export const reducer = slice.reducer;
export const { getAuditResult, getIndiceNote } = slice.actions;
export default slice;
