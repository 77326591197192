import React, { createContext, useReducer } from 'react';
let notifStorage = localStorage.hasOwnProperty('foo') ? parseInt(localStorage.getItem("notifs")) :0;

const initialState = {
    count: notifStorage
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INCREMENT': {
            localStorage.setItem("notifs", ++notifStorage);
            return {
                ...state,
                count: parseInt(localStorage.getItem("notifs")),
            };
        }
        default: {
            return { ...state };
        }
    }
};

const UpdateNotificationsContext = createContext({
    ...initialState,
    incrementNotifs: () => {},
});

export const NotifIncrementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const incrementNotifs = () => {
        dispatch({ type: 'INCREMENT' });
    };
    return (
        <UpdateNotificationsContext.Provider
            value={{
                ...state,
                incrementNotifs,
            }}
        >
            {children}
        </UpdateNotificationsContext.Provider>
    );
};
export default UpdateNotificationsContext;
