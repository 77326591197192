import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Slide from "@material-ui/core/Slide";
import { isElectron } from "../../helpers/isElectron";
import axios from "./../../utils/axios";
import { fetchvisitsTypesLocal } from "../../../electron/services/visitTypesService";

const initialState = {
  visitTypes: [],
  status: "idle",
};

export const fetchVisitsTypes = createAsyncThunk(
  "visitTypes/fetchVisitsTypes",
  async (body, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        const localdata = await fetchvisitsTypesLocal().then(
          (result) => result
        );
        data = localdata.filter((item) => item.module === body.formid);
        return data;
      } else {
        const response = await axios.get(body.url);
        data = await response.data;

        if (response.status === 200) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "visitTypes",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVisitsTypes.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchVisitsTypes.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.visitTypes = action.payload;
    },
    [fetchVisitsTypes.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const reducer = slice.reducer;
export const {} = slice.actions;
export default slice;
