import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { Profile, SidebarNav } from "./components";
import { useStore } from "../../../../contexts/JWTAuthContext";
import IconButton from "@material-ui/core/IconButton";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MapIcon from "@material-ui/icons/Map";
import { fetchVisitCenters } from "redux/slices/visitCenter";
import { useDispatch } from "react-redux";
const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(9) + 5,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Sidebar = (props) => {
  const {
    open,
    variant,
    onClose,
    className,
    onClick,
    sidebar,
    ...rest
  } = props;
  const { user } = useStore();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const pages = [
    {
      title: "Accueil",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Liste visite Centre",
      href: "/visitcenter",
      icon: <ListAltIcon />,
    },
    {
      title: "Formulaire Visite Centre",
      href: "/visitcenterForm",
      icon: <ListAltIcon />,
    },
    {
      title: "Liste circuit primaire",
      href: "/primaryCircuit",
      icon: <ListAltIcon />,
    },
    {
      title: "Formulaire Circuit Primaire",
      href: "/primarycircuitform",

      icon: <ListAltIcon />,
    },
    {
      title: "Liste des recaps",
      href: "/recaplist",
      icon: <ListAltIcon />,
    },
    {
      title: "Formulaire Récap",
      href: "/formulaire/récap",
      icon: <ListAltIcon />,
    },
    {
      title: "Liste de formulaire autre",
      href: "/otherlist",
      icon: <ListAltIcon />,
    },

    {
      title: "Formulaire Autre",
      href: "/otherForm",
      icon: <ListAltIcon />,
    },
    {
      title: "Audit hygiène CCL",
      href: "/audit-hygiène-ccl",
      icon: <ListAltIcon />,
    },
    {
      title: "Audit hygiène",
      href: "/audit-hygiène",
      icon: <ListAltIcon />,
    },
    {
      title: "Liste Plan d'action",
      href: "/actionplanlist",
      icon: <MapIcon />,
    },
    {
      title: "Plan d'action",
      href: "/actionplan",
      icon: <MapIcon />,
    },
  ];

  return (
    <Drawer
      ModalProps={{ onBackdropClick: onClose }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      anchor="left"
      //classes={{paper: classes.drawer}}
      onClose={open}
      open={open}
      variant={variant}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.toolbar}>
          <IconButton onClick={onClose}></IconButton>
        </div>
        <Profile profil={open} />

        <Divider className={classes.divider} />
        <SidebarNav
          onClick={onClick}
          sidebar={open}
          className={classes.nav}
          pages={pages}
          user={user}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
