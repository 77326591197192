import api from "../../services/api";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const connexionCheck = createAsyncThunk('auth/connexionCheck', async (query,thunkAPI) => {
  let data;
  try {
      const response = await axios.get('/checkSynchro');
      data = await response.data;
      if ((response.status = 200)) {

          return data.message;
      }
      throw new Error(response.statusText);
  } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
  }
});

// Slice
const slice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    user: [],
    isLoggedin: false,
    errors: null,
    connected:false,
    status:'idle',
    error:''
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = true;
      state.errors = null;
    },
    setErrors: (state, action) => {
      state.errors = action.payload.data;
      state.isLoading = false;
    },
    loginSuccess: (state, action) => {
      //localStorage.setItem("user", action.payload.data.user_id);
      /*localStorage.setItem("token_type", action.payload.data.token_type);
      localStorage.setItem("token", action.payload.data.access_token);
      localStorage.setItem("expires_at", action.payload.data.expires_at);*/
      state.user = action.payload.data;
      state.isLoggedin = true;
      state.isLoading = false;
    },
    // logoutSuccess: (state, action) => {
    //   localStorage.removeItem("user");
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("token_type");
    //   localStorage.removeItem("expires_at");
    //   state.user = [];
    //   state.isLoggedin = false;
    // },
  },
  extraReducers: {
    [connexionCheck.pending]: (state, action) => {
        state.status = 'loading';
        state.connected = false
    },
    [connexionCheck.fulfilled]: (state, action) => {
        state.status = 'succeeded';
        if(action.payload == 'ok' ){
        state.connected = true};
    },
    [connexionCheck.rejected]: (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.connected = false
    },
  }
});
export const reducer= slice.reducer;
// Actions
const { loginSuccess, logoutSuccess, setIsLoading, setErrors, } = slice.actions;

export const login = (data) => async (dispatch) => {
  dispatch(setIsLoading());
  try {
    const res = await api.post("/auth/login_check", data);
    dispatch(loginSuccess(res));
    window.location = "/";
  } catch (e) {
    dispatch(setErrors(e));
    return console.log(e.message);
  }
};

// export const logout = () => async (dispatch) => {
//   try {
//     const res = await api.get("/auth/logout/");
//     dispatch(logoutSuccess());
//     window.location = "/login";
//   } catch (e) {
//     dispatch(logoutSuccess());
//     window.location = "/login";
//     return console.error(e.message);
//   }
// };
