import React from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { isElectron } from '../helpers/isElectron';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const goto = useHistory().push;
  if (!isAuthenticated) {    
    if(isElectron()){
    // api_get(`auth/logout`).then((result) => {
      goto("/login");
    }else{ return <Redirect to={"/login"} />}
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
