import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";
export const getAllVisitTypes = async () => {
  return await api_get(`${configUrl}/visit_types?page=1&pagination=false`);
};
export const fetchvisitsTypesLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("visitTypes")
      .toArray()
      .then((visitTypes) => {
        resolve(visitTypes);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
