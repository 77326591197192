import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
import {
  addActionPlanItem,
  addActionPlanItem2,
  deleteActionPlanElectron,
  fetchAction_PlansLocal,
  saveActionPlanFormLocal,
  UpdateActionPlan,
} from "../../../electron/services/actionPlanService";
import { isElectron } from "../../helpers/isElectron";
import axios from "./../../utils/axios";

const initialState = {
  actionPlanData: [],
  rowData: [],
  status: "idle",
  createStatus: "idle",
  deleteStatus: "idle",
};
export const getActionPlan = createAsyncThunk(
  "ActionPlan/getActionPlan",
  async (actionPlan, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        const data = await fetchAction_PlansLocal().then((result) => result);
        return data;
      } else {
        const response = await axios.get(`/action_plans?page=&pagination=false`);
        data = await response?.data;
        if (response.status === 200) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createActionPlan = createAsyncThunk(
  "ActionPlan/createActionPlan",
  async (actionPlan, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/action_plans`, actionPlan);
      data = await response?.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "Plan d'action créé avec succés",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        window.location.href='#/actionplanlist'
        thunkAPI.dispatch(getActionPlan())
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createActionPlanLocal = createAsyncThunk(
  "ActionPlan/createActionPlanLocal",
  async (actionPlan, thunkAPI) => {
    let data;
    try {
      addActionPlanItem2(actionPlan);
      saveActionPlanFormLocal(actionPlan);
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: " formulaire action plan  créé avec succès Local",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      window.location.href='#/actionplanlist'
     
      setTimeout(()=>{
        thunkAPI.dispatch(getActionPlan());},500)
      
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateActionPlanLocal = createAsyncThunk(
  "ActionPlan/updateActionPlan",
  async (body, thunkAPI) => {
    let data;
    try {
      UpdateActionPlan({ id: body.id, data: body.data });
      thunkAPI.dispatch(getActionPlan());
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Plan d'action  mise à jour avec succès",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );     

    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateActionPlan = createAsyncThunk(
  "ActionPlan/updateActionPlan",
  async (body, thunkAPI) => {
    let data;
    try {
      if (!isElectron()) {
        const response = await axios.put(`/action_plans/${body.id}`, body.data);
        data = await response?.data;
        if (response.status === 200 || response.status === 201) {
          thunkAPI.dispatch(getActionPlan());
          thunkAPI.dispatch(
            enqueueSnackbar({
              message: "Plan d'action  mise à jour avec succès",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
              horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );

          return data;
        }
      } else {
        const actionPlan = body.data;
        delete actionPlan.isUpdated;
        const response = await axios.put(
          `/action_plans/${body.id}`,
          actionPlan
        );
        data = await response.data;
        if (response.status === 200 || response.status === 201) {
          thunkAPI.dispatch(getActionPlan());
          thunkAPI.dispatch(
            enqueueSnackbar({
              message: "Plan d'action  mise à jour avec succès",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
          return data;
        }
        throw new Error(response.statusText);
      }
      
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteActionPlan = createAsyncThunk(
  "ActionPlan/deleteActionPlan",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/action_plans/${id}`);

      data = await response.data;
      if (response.status === 200 || response.status === 204) {
        thunkAPI.dispatch(getActionPlan());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "Plan d'action supprimée avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteActionPlanLocal = createAsyncThunk(
  "ActionPlan/deleteActionPlanLocal",
  async (body, thunkAPI) => {
    let data;
    try {
      deleteActionPlanElectron({ id: body.id, data: body.data });
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Plan d'action supprimée avec succès",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );      thunkAPI.dispatch(getActionPlan());

      return data;
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "ActionPlan",
  initialState,
  reducers: {
    getActionPlanRowData: (state, action) => {
      localStorage.setItem("actionPlanData", JSON.stringify(action.payload));
      state.rowData = action.payload;
    },
  },
  extraReducers: {
    [getActionPlan.pending]: (state, action) => {
      state.status = "loading";
    },
    [getActionPlan.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.actionPlanData = action.payload;
    },
    [getActionPlan.rejected]: (state, action) => {
      state.status = "error";
    },
    [createActionPlan.pending]: (state, action) => {
      state.createStatus = "loading";
    },
    [createActionPlan.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
      state.actionPlanData.push(action.payload);
    },
    [createActionPlan.rejected]: (state, action) => {
      state.createStatus = "error";
    },
    [deleteActionPlan.pending]: (state, action) => {
      state.deleteStatus = "loading";
    },
    [deleteActionPlan.fulfilled]: (state, action) => {
      state.deleteStatus = "succeeded";
      state.actionPlanData.filter((item) => item.id !== action.payload);
    },
    [deleteActionPlan.rejected]: (state, action) => {
      state.deleteStatus = "error";
    },
  },
});

export const reducer = slice.reducer;
export const { getActionPlanRowData } = slice.actions;
export default slice;
