import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
import { isElectron } from "../../helpers/isElectron";
import {
  addPrimaryCircuit,
  addVisitCenter,
  fetchCircuitPrimaireLocal,
  fetchVisitCentersByDateLocal,
  fetchVisitCentersLocal,
  saveVisitCenterFormLocal,
} from "./../../../electron/services/visitCenterForm";
import {
  fetchCenterbyZoneLocal,
  fetchCenterbyTypeAndZoneLocal,
  fetchCenterbyTypeLocal,
} from "../../../electron/services/centerService";
import axios from "./../../utils/axios";
import { addActionPlanItem } from "../../../electron/services/actionPlanService";
import { getActionPlan } from "./actionPlan";

const initialState = {
  visitFormCenters: [],
  PrimaryCircuitFormCenters: [],
  otherFormCenters: [],
  auditCenters: [],
  centersStatus: "idle",
  visitCenterStatus: "idle",
  visitCenterByDateStatus: "idle",
  pointToImprove: [],
  visitCenters: [],
  allCentersVisitStatus: [],
  BaseFormData: [],
  rowdata: [],
  visitCenterByDate: [],
  createVisitestatus: "idle",
  countVisitCenter: "",
};

export const fetchCenters = createAsyncThunk(
  "visitCenter/fetchCenters",
  async (body, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        if (body.formid === "VisitCenterForm") {
          data = await fetchCenterbyTypeAndZoneLocal({
            zone: body.zone,
            type: "Centre de Collecte",
          }).then((result) => result);

          const filteredData = data.filter(
            (item) => item.governorate?.id === body.zone
          );
          return { data: filteredData, formid: body.formid };
        } else if (body.formid === "PrimaryCircuitForm") {
          data = await fetchCenterbyTypeAndZoneLocal({
            zone: body.zone,
            type: "Ferme",
          }).then((result) => result);
          const filteredData = data.filter(
            (item) => item.governorate?.id === body.zone
          );
          return { data: filteredData, formid: body.formid };
        } else if (body.formid === "otherform") {
          data = await fetchCenterbyZoneLocal(body.zone).then(
            (result) => result
          );

          return { data: data, formid: body.formid };
        } else if (body.formid === "audit") {
          data = await fetchCenterbyZoneLocal(body.zone).then(
            (result) => result
          );
          return { data: data, formid: body.formid };
        } else if (body.formid === "actionPlan") {
          data = await fetchCenterbyTypeLocal("Centre de Collecte").then(
            (result) => result
          );
          return { data: data, formid: body.formid };
        }
      } else {
        const response = await axios.get(body.url);
        data = await response.data;

        if (response.status === 200) {
          return { data: data, formid: body.formid };
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchVisitCenters = createAsyncThunk(
  "visitCenter/fetchVisitCenters",
  async (query, thunkAPI) => {
    let data;

    try {
      if (isElectron()) {
        if (query.type === "CCL") {
          const data = await fetchVisitCentersLocal().then((result) => result);
          return data;
        } else {
          const data = await fetchCircuitPrimaireLocal().then(
            (result) => result
          );
          return data;
        }
      } else {
        const response =
          (query.type === "CCL" &&
            (await axios.get(
              `/visit_centers?search=${query.inputText}&type=${
                query.type
              }&page=${Number(query.page) + 1}&itemsPerPage=${
                query.rowsPerPage
              }&pagination=true`
            ))) ||
          (query.type === "CP" &&
            (await axios.get(
              `/visit_centers?type=${query.type}&page=&pagination=false`
            )));
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchCountVisitCenters = createAsyncThunk(
  "visitCenter/fetchCountVisitCenters",
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/visit_centers/count`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createVisitCentersLocal = createAsyncThunk(
  "visitCenter/createVisitCentersLocal",
  async (visit, thunkAPI) => {
    let data;
    try {
      addVisitCenter(visit);
      saveVisitCenterFormLocal(visit);
      addActionPlanItem(visit);
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            visit.type === "CCL"
              ? "Formulaire visite center créé avec succès"
              : "Formulaire circuit primaire créé avec succès",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      if (visit.type === "CCL") {
        window.location.href = "#/visitcenter";
      }
      setTimeout(() => {
        thunkAPI.dispatch(fetchVisitCenters({ type: visit.type }));
        thunkAPI.dispatch(getActionPlan());
      }, 500);
      if (visit.type === "CP") {
        window.location.href = "#/primaryCircuit";
        setTimeout(() => {
          thunkAPI.dispatch(fetchVisitCenters({ type: visit.type }));
          thunkAPI.dispatch(getActionPlan());
        }, 500);
      }

      thunkAPI.dispatch(fetchCenters());
      return data;
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createVisitCenters = createAsyncThunk(
  "visitCenter/createVisitCenters",

  async (visit, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/visit_centers`, visit);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              visit.type === "CCL"
                ? "Formulaire visite center créé avec succès"
                : "Formulaire circuit primaire créé avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        if (visit.type === "CCL") {
          window.location.href = "#/visitcenter";
        }
        if (visit.type === "CP") {
          window.location.href = "#/primaryCircuit";
        }

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "les données ne sont pas valides",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchVisitCentersByDate = createAsyncThunk(
  "visitCenter/fetchVisitCentersByDate",

  async (query, thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        const data = await fetchVisitCentersByDateLocal(query.date).then(
          (result) => result
        );

        return data;
      } else {
        const response = await axios.get(
          `/visit_centers?page=1&pagination=false&date=${query.date}&exists[recap]=false`
        );

        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "visitCenter",
  initialState,
  reducers: {
    getBaseFormData: (state, action) => {
      state.BaseFormData = action.payload;
    },
    emptyVisitCenter: (state, action) => {
      state.visitCenters = [];
    },
    getTableRowData: (state, action) => {
      localStorage.setItem("rowdata", JSON.stringify(action.payload));
      state.rowdata = action.payload;
    },
    getPointToImprove: (state, action) => {
      state.pointToImprove.push(action.payload);
    },
    deletePointToImprove: (state, action) => {
      state.pointToImprove = state.pointToImprove.filter(
        (item) => item.id !== action.payload
      );
    },
    emptyPointToImprove: (state, action) => {
      state.pointToImprove = [];
    },
  },
  extraReducers: {
    [fetchCenters.pending]: (state, action) => {
      state.centersStatus = "loading";
    },
    [fetchCenters.fulfilled]: (state, action) => {
      state.centersStatus = "succeeded";
      if (
        action.payload.formid === "VisitCenterForm" ||
        action.payload.formid === "actionPlan"
      ) {
        state.visitFormCenters = action.payload.data;
      } else if (action.payload.formid === "PrimaryCircuitForm") {
        state.PrimaryCircuitFormCenters = action.payload.data;
      } else if (action.payload.formid === "otherform") {
        state.otherFormCenters = action.payload.data;
      } else if (action.payload.formid === "audit") {
        state.auditCenters = action.payload.data;
      }
    },
    [fetchCenters.rejected]: (state, action) => {
      state.centersStatus = "error";
    },

    [fetchVisitCenters.pending]: (state, action) => {
      state.visitCenterStatus = "loading";
    },
    [fetchVisitCenters.fulfilled]: (state, action) => {
      state.visitCenterStatus = "succeeded";
      state.visitCenters = action.payload;
    },
    [fetchVisitCenters.rejected]: (state, action) => {
      state.visitCenterStatus = "error";
    },
    [fetchCountVisitCenters.pending]: (state, action) => {
      state.visitCenterStatus = "loading";
    },
    [fetchCountVisitCenters.fulfilled]: (state, action) => {
      state.visitCenterStatus = "succeeded";
      state.countVisitCenter = action.payload;
    },
    [fetchCountVisitCenters.rejected]: (state, action) => {
      state.visitCenterStatus = "error";
    },
    [fetchVisitCenters.rejected]: (state, action) => {
      state.allCentersVisitStatus = "error";
    },
    [fetchVisitCentersByDate.pending]: (state, action) => {
      state.visitCenterByDateStatus = "loading";
    },
    [fetchVisitCentersByDate.fulfilled]: (state, action) => {
      state.visitCenterByDateStatus = "succeeded";
      if (action?.payload?.length > 0) {
        state.visitCenterByDate = action.payload;
      }
    },
    [fetchVisitCentersByDate.rejected]: (state, action) => {
      state.visitCenterByDateStatus = "error";
    },
    [createVisitCenters.pending]: (state, action) => {
      state.createVisitestatus = "loading";
    },
    [createVisitCenters.fulfilled]: (state, action) => {
      state.createVisitestatus = "succeeded";
    },
    [createVisitCenters.rejected]: (state, action) => {
      state.createVisitestatus = "error";
    },
  },
});

export const reducer = slice.reducer;
export const {
  getTableRowData,
  checkValue,
  getPointToImprove,
  emptyPointToImprove,
  deletePointToImprove,
  emptyVisitCenter,
} = slice.actions;
export default slice;
