import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
const initialState = {
  analysisVisits: [],
  analysisVisitsData: [],
  showData: [],
  isEmpty: false,
  analyseRecap: [],
  analyseCP: [],
  analyseCCL: [],
  row: [],
};

export const fetchanalysis_visits = createAsyncThunk(
  "analysis_visits/fetchanalysis_visits",
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/analysis_visits?page=&pagination=false`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createanalysis_visits = createAsyncThunk(
  "analysis_visits/createanalysis_visits",
  async (analysis_visits, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/analysis_visits`, analysis_visits);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "analyses visite créé avec succés",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteanalysis_visits = createAsyncThunk(
  "analysis_visits/deleteanalysis_visits",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/analysis_visits/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchanalysis_visits());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: "analyses visites supprimé avec succès",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "analysis_visits",
  initialState,
  reducers: {
    getAnalyzeVisitCenterData: (state, action) => {
      state.analysisVisitsData.push(action.payload);
    },
    getRowData: (state, action) => {
      state.row = action.payload;
      state.showData = [];
    },

    getAnalyse: (state, action) => {
      if (action.payload.formid === "VisitCenterForm") {
        state.analyseCCL.push(action.payload.data);
      } else if (action.payload.formid === "PrimaryCircuitForm")
        state.analyseCP.push(action.payload.data);
      else {
        if (action.payload.formid === "recap")
          state.analyseRecap.push(action.payload.data);
      }
    },
    updateAnalyse: (state, action) => {
      if (action.payload.formid === "VisitCenterForm") {
        state.analyseCCL = state.analyseCCL.map((item) =>
          item.id === action.payload.data.id ? action.payload.data : item
        );
      } else if (action.payload.formid === "PrimaryCircuitForm")
        state.analyseCP = state.analyseCP.map((item) =>
          item.id === action.payload.data.id ? action.payload.data : item
        );
      else {
        if (action.payload.formid === "recap")
          state.analyseRecap = state.analyseRecap.map((item) =>
            item.id == action.payload.data.id ? action.payload.data : item
            
          );
      }
    },
    deleteAnalyse: (state, action) => {
      if (action.payload.formid === "VisitCenterForm") {
        state.analyseCCL = state.analyseCCL.filter(
          (item) => item.id !== action.payload.id
        );
      } else if (action.payload.formid === "PrimaryCircuitForm") {
        state.analyseCP = state.analyseCP.filter(
          (item) => item.id !== action.payload.id
        );
      } else {
        if (action.payload.formid === "recap") {
          state.analyseRecap = state.analyseRecap.filter(
            (item) => item.id !== action.payload.id
          );
        }
      }
    },
    showAnalyseData: (state, action) => {
      state.showData = action.payload;
      state.row = [];
    },
    isEmptyRow: (state, action) => {
      state.isEmpty = true;
    },
    emptyAnalyse: (state, action) => {
      if (action.payload.formid === "VisitCenterForm") {
        state.analyseCCL = [];
      } else if (action.payload.formid === "PrimaryCircuitForm") {
        state.analyseCP = [];
      } else {
        if (action.payload.formid === "recap") {
          state.analyseRecap = [];
        }
      }
    },
    emptyRow: (state, action) => {
      state.row = [];
    },
    emptyShowData: (state, action) => {
      state.showData = [];
      state.isEmpty = !state.isEmpty;
    },
  },
  extraReducers: {
    [fetchanalysis_visits.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchanalysis_visits.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.analysisVisits = action.payload;
    },
    [fetchanalysis_visits.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const {
  getAnalyzeVisitCenterData,
  getRowData,
  showAnalyseData,
  getAnalyse,
  updateAnalyse,
  deleteAnalyse,
  emptyAnalyse,
  isEmptyRow,
  emptyRow,
  emptyShowData,
} = slice.actions;
export default slice;
