import Dexie from "dexie";

const db = new Dexie("deliceDB");
db.version(1).stores({
  centers:
    "++id,code,name,governorate,zone,governorate.id,governorate.zone.id,site,chef,type,drivers,breeders,lastAudit,numberColdSpots,quantityColdStorage,quantityCollectors,quantityHawkers,quantityIsothermalStorage,quantityMorning,quantityNight,quantityTotal,isLocal",
  zones: "++id,name,governorate,isLocal",
  otherForm: "++id,form,isLocal",
  actionForm: "++id,form,isLocal",
  auditForm: "++id,form,isLocal",
  recapForm: "++id,form,isLocal",
  requirements_themes: "++id",
  notes: "++id",
  visitCenterForm: "++id,form,isLocal",
  actionPlans:
    "++id,pointToImprove,timeLimit,cost,progress,payBack,correction,date,center,zone,action,isLocal,isUpdated,isDeleted",
  visitCentersData:
    "++id,actionPlans,analysisVisits,center,collectors,date,hawkers,instantCold,numberColdSpots,observation,quantityColdStorage,quantityCollactors,quantityHawkers,quantityIsothermalStorage,quantityMorning,quantityNight,quantityTotal,time,type,user,visitCenterCheks,recap",
  auditList: "++id",
  analysis_parameters: "++id,visite",
  refusals: "++id",
  drivers: "++id,center.id",
  checks: "++id",
  others: "++id",
  recaps: "++id",
  circuitprimaire: "++id",
  governorates: "++id",
  updateAudit: "++id,form,isLocal",
  visitTypes: "++id",
  breeder: "++id",
  allVisitCentre:
    "++id,actionPlans,analysisVisits,center,collectors,date,hawkers,instantCold,numberColdSpots,observation,quantityColdStorage,quantityCollactors,quantityHawkers,quantityIsothermalStorage,quantityMorning,quantityNight,quantityTotal,time,type,user,visitCenterCheks,recap",
});

export default db;
