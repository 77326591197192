import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { setItemFromStorage } from "../../../../../../utils/Storage.js";
import { useStore } from "contexts/JWTAuthContext.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { className,profil, ...rest } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const {user}=useStore()
  useEffect(() => {
    // getData();
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     if (data.roles) {
  //       setUser({
  //         ...user,
  //         name: (data && data.name) || "",
  //         bio: (data?.roles[0]) || "",
  //       });
  //     }
  //   }
  // }, [data]);
  // const setPermissions= async (permissions)=>{
  //   await permissions && permissions.map(permission=>{
  //     return permission.permissions.map(async(perm,index)=>{
  //       await setItemFromStorage(perm.front_path,"permission");
  //       await setItemFromStorage(perm.menu,"permission");
  //     })
  //   })
  // }
  const getData = () => {

  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt='Person'
        className={classes.avatar}
        component={RouterLink}
       // src={user.avatar}
        to='/account'
      />
      <Typography className={classes.name} variant='h5'>
        {(user && user.username) || ""}
      </Typography>
   
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
