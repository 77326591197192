/* -- Headers & API_URL ------------------ */
import axiosInstance from "utils/axios";
import headers from "./headers";

//const apiUrl = process.env.REACT_APP_API_URL;

/* -- Default methods -------------------- */
const api_get = async (base_url) => {
  return axiosInstance.get(`${base_url}`
  ).then((response) => {
    return response.data;
  });
};



/* -- Export methods --------------------- */
export {
  api_get,
};
