import { serialize } from "object-to-formdata";
import {
  createActionPlan,
  deleteActionPlan,
  getActionPlan,
  updateActionPlan,
} from "redux/slices/actionPlan";
import { addAuditHygien, updateAuditComment } from "redux/slices/audit_hygien";
import { createOtherForm } from "redux/slices/otherForm";
import { addRecapForm } from "redux/slices/recap_form";
import { createVisitCenters } from "redux/slices/visitCenter";
import { synchroniseDatabase } from "../../electron/db/dbService";
import { fetchUpdatedAuditLocals } from "../../electron/services/auditForm";
import { fetchAllAuditFormLocals } from "../../electron/services/auditFormService";
import {
  fetchAllActionFormLocals,
  fetchAllVisitCenterFormLocals,
  fetchdeletedActionPlanLocals,
  fetchOtherFormLocals,
  fetchUpdatedActionPlanLocals,
} from "../../electron/services/divisionService";
import { fetchAllRecapFormLocals } from "../../electron/services/recapFormService";

const SynchroniseDB = (
  dispatch,
  setSynchronised,
  setNotifications,
  connexionCheck
) => {
  fetchAllVisitCenterFormLocals()
    .then((forms) => {
      if (forms) {
        forms.forEach(function(form) {
          dispatch(createVisitCenters(form?.form));
        });
      }
    })
    .catch(function(err) {
      console.error("Could not update ", err);
    })
    .then(() => {
      fetchOtherFormLocals()
        .then((forms) => {
          let formData = {};
          if (forms) {
            forms.forEach(function(form) {
              formData = serialize(form.form, { indices: true });
              dispatch(createOtherForm({ otherdata: formData }));
            });
          }
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        });
    })
    .then(() => {
      fetchAllActionFormLocals()
        .then((forms) => {
          if (forms) {
            forms.forEach(function(form) {
              dispatch(createActionPlan(form?.form));
            });
          }
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        });
    })
    .then(() => {
      fetchAllAuditFormLocals()
        .then((forms) => {
          let formData = {};

          if (forms) {
            forms.forEach(function(form) {
              formData = serialize(form.form, { indices: true });
              dispatch(addAuditHygien({ requestValues: formData }));
            });
          }
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        });
    })
    .then(() => {
      fetchUpdatedAuditLocals()
        .then((forms) => {
          if (forms) {
            forms.forEach(function(form) {
              dispatch(
                updateAuditComment({
                  id: parseInt(form?.form.id),
                  data: {
                    comment: form.form.data.comment,
                    validated: form.form.data.validated,
                  },
                })
              );
            });
          }
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        });
    })
    .then(() => {
      fetchUpdatedActionPlanLocals()
        .then((forms) => {
          if (forms) {
            forms.forEach(function(form) {
              Object.keys(form).map(function(key, index) {
                if (form[key]?.length === 0) {
                  delete form[key];
                }
              });
              delete form.isUpdated;
              dispatch(
                updateActionPlan({
                  id: form.id,
                  data: {
                    center: form?.center.id,
                    correction: form.correction,
                    cost: Math.floor(100 * parseFloat(form.cost)) / 100,
                    date: form.date,
                    payBack: Math.floor(100 * parseFloat(form.payBack)) / 100,
                    pointToImprove: form.pointToImprove,
                    progress: form.progress,
                    timeLimit: form.timeLimit,
                  },
                })
              );
            });
          }
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        });
    })
    .then(() => {
      fetchdeletedActionPlanLocals()
        .then((forms) => {
          if (forms) {
            forms.forEach(function(form) {
              dispatch(deleteActionPlan(form.id));
            });
          }
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        });
    })
    .then(() => {
      fetchAllRecapFormLocals()
        .then((forms) => {
          if (forms) {
            forms.forEach(function(form) {
              setTimeout(() => {
                dispatch(addRecapForm({ requestValues: form.form }));
              }, 1000);
            });
          }
        })
        .catch(function(err) {
          console.error("Could not update ", err);
        });
    })

    .catch(function(err) {
      console.error("Could not update ", err);
    })
    .finally(() => {
      setSynchronised(true);
      localStorage.setItem("notifs", 0);
      setNotifications(0);
      synchroniseDatabase();
    });
};

export default SynchroniseDB;
