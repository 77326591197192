import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Budget, TotalUsers } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditHygien } from "redux/slices/audit_hygien";
import {
  fetchCountVisitCenters,
  fetchVisitCenters,
} from "redux/slices/visitCenter";
import { connexionCheck } from "redux/slices/auth";
import { isElectron } from "helpers/isElectron";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    minHeight: "800px",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { auditHygiene } = useSelector((state) => state.auditHygien);
  const { visitCenters } = useSelector((state) => state.visitCenter);

  useEffect(() => {
    if (auditHygiene.length <= 0 && isElectron()) {
      dispatch(fetchAuditHygien());
    }

    if (visitCenters.length <= 0 && isElectron()) {
      dispatch(fetchVisitCenters({ type: "CCL" }));
    }
    dispatch(fetchCountVisitCenters());
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <Budget />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
