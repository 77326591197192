import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { enqueueSnackbar } from "./notifier";
import Slide from "@material-ui/core/Slide";
import { isElectron } from "../../helpers/isElectron";
import { fetchAnalysis_parametersbyTypeLocal } from "../../../electron/services/analysis_parametersService";

const initialState = {
  analysis_parameters: [],
};

export const fetchanalysis_parameters = createAsyncThunk(
  "analysis_parameters/fetchanalysis_parameters",
  async (query, thunkAPI) => {
    let data;

    try {
      if (isElectron()) {
        data = await fetchAnalysis_parametersbyTypeLocal(query.visite).then(
          (result) => result
        );
        const filteredData = data.filter(
          (item) => item.activated === query.activated
        );
        return filteredData;
      } else {
        const response = await axios.get(
          `/analysis_parameters?visite=${query.visite}&activated=${query.activated}&page=&pagination=false`
        );
        data = await response.data;
        if (response.status === 200 || response.status === 201) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "analysis_parameters",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchanalysis_parameters.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchanalysis_parameters.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.analysis_parameters = action.payload;
    },
    [fetchanalysis_parameters.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;

export default slice;
