import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Badge from "@material-ui/core/Badge";
import { IconButton, Typography } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { notificationsSeen } from "redux/slices/audit_hygien";
import { useDispatch } from "react-redux";
import { isElectron } from "helpers/isElectron";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginLeft: "8px",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  menuItemTitle: {
    color: "#18698d",
    fontSize: "16px",
    fontWeight: "bold",
    top: "79px",
    left: "860px",
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingBottom: "20px",
    opacity: "1",
  },
  messageHeader: {
    display: "flex",
    flexDirection: "row",
  },
  messageTitle: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "bold",
    top: "134px",
    left: "897px",
    paddingLeft: "10px",
    opacity: "1",
  },
  messageBody: {
    top: "159px",
    left: "897px",
    fontSize: "14px",
    color: "#7c8494",
    opacity: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
}));

const NotificationsDropwDown = ({ totalNotifications, electron, userId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    dispatch(notificationsSeen({ id: userId }));
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.dropdown} onClick={handleToggle}>
      <IconButton color="inherit">
        <Badge
          badgeContent={
            isElectron()
              ? totalNotifications
              : totalNotifications?.pagination?.totalItems
          }
          color="secondary"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              marginTop: "25px",
              marginRight: electron ? "150px" : "100px",
              width: "280px",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <Typography className={classes.menuItemTitle}>
                    Notifications
                  </Typography>
                  {totalNotifications > 0 && isElectron() ? (
                    <MenuItem
                      onClick={handleClose}
                      style={{ backgroundColor: "rgba(233, 248, 253, 0.6)" }}
                    >
                      <Link to="#" key="notifs">
                        <div className={classes.messageHeader}>
                          <VisibilityIcon style={{ color: "gray" }} />
                          <Typography className={classes.messageTitle}>
                            {" "}
                            Bonjour
                          </Typography>
                        </div>
                        <Typography className={classes.messageBody}>
                          Vous avez des données à synchroniser
                        </Typography>
                      </Link>
                    </MenuItem>
                  ) : totalNotifications.pagination.totalItems > 0 &&
                    !isElectron() ? (
                    totalNotifications?.data?.map((el) => {
                      return (
                        <MenuItem
                          onClick={handleClose}
                          style={{
                            backgroundColor: "rgba(233, 248, 253, 0.6)",
                          }}
                        >
                          <Typography className={classes.messageBody}>
                            {el?.contenu}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem onClick={handleClose}>
                      Pas de notifications{" "}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default NotificationsDropwDown;
