import { api_get } from "../../src/helpers/Api";
import { configUrl } from "../configUrl";
import db from "../db/db";

export const getChecks = async () => {
  return await api_get(
    `${configUrl}/preliminary_checks?page=&pagination=false`
  );
};

export const fetchChecksLocal = () => {
  return new Promise((resolve, reject) => {
    db.table("checks")
      .toArray()
      .then((checks) => {
        resolve(checks);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
