import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { isElectron } from "../../helpers/isElectron";
import { fetchGovernoratesLocal } from "../../../electron/services/governoratesService";
const initialState = {
  governorates: [],
  status: "idle",
};
export const fetchGovernorate = createAsyncThunk(
  "governorate/fetchGovernorate",
  async () => {
    let data;
    try {
      if (isElectron()) {
        data = await fetchGovernoratesLocal().then((result) => result);
        return data;
      } else {
        const response = await axios.get(
          `/governorates?page=&pagination=false`
        );
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "governorates",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGovernorate.pending]: (state) => {
      state.status = "loading";
    },
    [fetchGovernorate.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.governorates = action.payload;
    },
    [fetchGovernorate.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;

export default slice;
