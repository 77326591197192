import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { enqueueSnackbar } from './notifier';
import Slide from '@material-ui/core/Slide';
import { isElectron } from "../../helpers/isElectron";
import {fetchNotesLocal} from '../../../electron/services/noteService'
const initialState = {
  note: [],
};

export const fetchNotes = createAsyncThunk(
  "note/fetchNotes",
  async (page,thunkAPI) => {
    let data;
    try {
      if (isElectron()) {
        data =  await fetchNotesLocal().then((result)=> result  );
        /// = await response.data;
        return data;}else{
      const response = await axios.get(`/notes?page=&pagination=false`,);
      data = await response.data;
      if ((response.status = 200)) {
     
        return data;
      }
      throw new Error(response.statusText);
    }} catch (err) {
      // thunkAPI.dispatch(
      //   enqueueSnackbar({
      //     message: err.message,
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: "error",
      //       anchorOrigin: {
      //         vertical: "bottom",
      //         horizontal: "center",
      //       },
      //      TransitionComponent: Slide,
      //     },
      //   })
      // );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);


const slice = createSlice({
  name: "note",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNotes.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchNotes.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.note = action.payload;
      localStorage.setItem("note", JSON.stringify(state.note));
    
    },
    [fetchNotes.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;

export default slice;
